import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { GridFooterContainer, GridFooter } from "@mui/x-data-grid-pro";

function CustomFooterStatusComponent(props) {
	const { footerMessage, footerActionButton } = props;
	return (
		<GridFooterContainer>
			<Box
				sx={theme => ({
					flexGrow: 1,
					flexShrink: 1,
					color: theme.palette.grey[800]
				})}
			>
				{footerActionButton}
				<Typography variant="caption">{footerMessage}</Typography>
			</Box>
			<GridFooter
				sx={{
					border: "none"
				}}
			/>
		</GridFooterContainer>
	);
}

CustomFooterStatusComponent.propTypes = {
	footerMessage: PropTypes.string,
	footerActionButton: PropTypes.shape()
};
CustomFooterStatusComponent.defaultProps = {
	footerMessage: null,
	footerActionButton: null
};

export default CustomFooterStatusComponent;
