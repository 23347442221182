import React, { useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import RouterLink from "../Routing/LinkWithQuery";
import LoadingBackdrop from "../LoadingBackdrop/LoadingBackdrop";
import useResource, { Operations } from "../../hooks/useResource";
import { isEmpty } from "../../utils/objectUtils";
import CrudTableRowActionMenu from "./CrudTableRowActionMenu";
import ButtonContainedDark from "../ButtonContainedDark/ButtonContainedDark";
import lc from "./localeContent";
import CrudDataGrid from "../CrudDataGrid/CrudDataGrid";
import commonLocaleContent from "../../screens/commonLocaleContent";

export const COLUMN_KEY_STATUS = "status";

export const getPluralResourceName = resourceName => {
	return `${resourceName}s`;
};

const UPDATED_AT_KEY = "updatedAt";

const addCreatedUpdatedColumns = (columnsConfig, overrideDefaultColumns) => {
	const clonedConfig = columnsConfig.map(item => ({ ...item }));
	const statusColumnIndex = clonedConfig.findIndex(
		column => column.key === COLUMN_KEY_STATUS
	);
	// Add Created and Updated columns either after the status column or else as the last columns in the table
	if (!overrideDefaultColumns)
		clonedConfig.splice(
			statusColumnIndex >= 0 ? statusColumnIndex + 1 : clonedConfig.length + 1,
			0,
			{
				key: "createdAt",
				name: lc.COLUMN_HEADERS.CREATED_AT,
				cellDataType: "date"
			},
			{
				key: UPDATED_AT_KEY,
				name: lc.COLUMN_HEADERS.UPDATED_AT,
				cellDataType: "date"
			},
			{
				key: "guid",
				name: commonLocaleContent.COLUMN_HEADER_GUID,
				cellDataType: "string"
			}
		);
	return clonedConfig;
};
const addCrudResourceColumns = (
	columnsConfig,
	resourceRoute,
	resourceEndpoint,
	resourceName,
	deleteItem,
	childResourceConfig,
	disableDelete,
	showActions,
	primaryColumnConfig,
	tableRowActionMenuCustomOptions,
	disableClone,
	customStartPathEndPoint,
	overrideDefaultColumns,
	addLinkToPrimaryCol
) => {
	const columns = [
		{
			name: primaryColumnConfig?.name || lc.COLUMN_HEADERS.NAME,
			key: primaryColumnConfig?.key || "name",
			preventVisibilityToggle: true,
			resourceRoute,
			formatter: (value, rowData) =>
				addLinkToPrimaryCol ? (
					<Link
						to={`/dashboard/${resourceRoute || resourceEndpoint}/${rowData.id}`}
						color="inherit"
						component={RouterLink}
					>
						{value}
					</Link>
				) : (
					value
				)
		},
		...addCreatedUpdatedColumns(columnsConfig, overrideDefaultColumns)
	];

	if (showActions) {
		columns.unshift({
			key: "actions",
			preventVisibilityToggle: true,
			formatter: (value, rowData) => (
				<CrudTableRowActionMenu
					rowData={rowData}
					rowItemNameKey={primaryColumnConfig?.key || "name"}
					resourceRoute={resourceRoute}
					resourceEndpoint={resourceEndpoint}
					resourceName={resourceName}
					deleteItemCallback={disableDelete ? null : deleteItem}
					childResourceConfig={childResourceConfig}
					customOptions={tableRowActionMenuCustomOptions}
					disableClone={disableClone}
					customStartPathEndPoint={customStartPathEndPoint}
				/>
			)
		});
	}
	return columns;
};

function CrudDataTable(props) {
	const {
		resourceName,
		resourceNamePlural,
		columnsConfig,
		resourceEndpoint,
		resourceRoute,
		disclaimer,
		requestParams,
		noDataActionButtonConfig,
		footerActionButtonConfig,
		childResourceConfig,
		disableDelete,
		primaryColumnConfig,
		tableRowActionMenuCustomOptions,
		disableClone,
		paginationConfig,
		heading,
		customStartPathEndPoint,
		overrideDefaultColumns,
		defaultSortColumnKey,
		gridHeight,
		tableTestId,
		showActions,
		addLinkToPrimaryCol,
		showGuidColumn
	} = props;

	const [data, error, isLoading] = useResource(
		resourceEndpoint,
		Operations.LIST,
		{ requestParams },
		customStartPathEndPoint
	);

	const [deletedItems, setDeletedItems] = useState([]);
	const deleteItem = useCallback(
		itemId => setDeletedItems([...deletedItems, itemId]),
		[deletedItems]
	);
	// Setup sorting

	const columns = useMemo(
		() =>
			addCrudResourceColumns(
				columnsConfig,
				resourceRoute,
				resourceEndpoint,
				resourceName,
				deleteItem,
				childResourceConfig,
				disableDelete,
				showActions,
				primaryColumnConfig,
				tableRowActionMenuCustomOptions,
				disableClone,
				customStartPathEndPoint,
				overrideDefaultColumns,
				addLinkToPrimaryCol
			),
		[
			columnsConfig,
			resourceRoute,
			resourceEndpoint,
			resourceName,
			deleteItem,
			childResourceConfig,
			disableDelete,
			primaryColumnConfig,
			tableRowActionMenuCustomOptions,
			disableClone,
			customStartPathEndPoint,
			overrideDefaultColumns,
			showActions,
			addLinkToPrimaryCol
		]
	);

	const filteredData = useMemo(() => {
		if (!data) return [];
		return data.filter(row => !deletedItems.includes(row.id));
	}, [data, deletedItems]);

	const accessibleTableDescription = useMemo(() => {
		const pluralResourceName =
			resourceNamePlural || getPluralResourceName(resourceName);
		return lc.ACCESSIBLE_TABLE_DESCRIPTION(pluralResourceName);
	}, [resourceNamePlural, resourceName]);

	return (
		<>
			<LoadingBackdrop isOpen={isLoading} />
			{error && (
				// This is a temporary solution pending https://beezag.jira.com/browse/CP-790
				<Typography>{error}</Typography>
			)}
			{!error &&
				(noDataActionButtonConfig && !isLoading && isEmpty(data) ? (
					<Box
						sx={theme => ({
							display: "flex",
							height: theme.spacing(16),
							alignItems: "center",
							justifyContent: "center"
						})}
					>
						<ButtonContainedDark
							label={noDataActionButtonConfig.label}
							linkToRoute={noDataActionButtonConfig.to}
							size="large"
							StartIconComponent={AddIcon}
						/>
					</Box>
				) : (
					<CrudDataGrid
						disclaimer={disclaimer}
						columns={columns}
						filteredData={filteredData}
						paginationConfig={paginationConfig}
						heading={heading}
						gridHeight={gridHeight}
						tableTestId={tableTestId}
						primaryColumnKey={primaryColumnConfig?.key}
						// TODO: Is defaultSortColumnKey an exposed prop of CrudDataGrid?
						defaultSortColumnKey={defaultSortColumnKey}
						showGuidColumn={showGuidColumn}
						footerActionButton={
							(footerActionButtonConfig && !isEmpty(filteredData) && (
								<ButtonContainedDark
									label={footerActionButtonConfig.label}
									linkToRoute={footerActionButtonConfig.to}
									StartIconComponent={AddIcon}
								/>
							)) ||
							null
						}
						gridAriaLabel={accessibleTableDescription}
					/>
				))}
		</>
	);
}

CrudDataTable.propTypes = {
	resourceName: PropTypes.string.isRequired,
	resourceNamePlural: PropTypes.string,
	columnsConfig: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	resourceEndpoint: PropTypes.string.isRequired,
	customStartPathEndPoint: PropTypes.func,
	resourceRoute: PropTypes.string,
	disclaimer: PropTypes.string,
	requestParams: PropTypes.shape(),
	noDataActionButtonConfig: PropTypes.shape(),
	footerActionButtonConfig: PropTypes.shape(),
	childResourceConfig: PropTypes.shape(),
	disableDelete: PropTypes.bool,
	primaryColumnConfig: PropTypes.shape({
		key: PropTypes.string,
		name: PropTypes.string
	}),
	tableRowActionMenuCustomOptions: PropTypes.arrayOf(PropTypes.shape()),
	disableClone: PropTypes.bool,
	paginationConfig: PropTypes.shape({
		defaultRowsPerPage: PropTypes.number
	}),
	heading: PropTypes.string,
	overrideDefaultColumns: PropTypes.bool,
	defaultSortColumnKey: PropTypes.string,
	showActions: PropTypes.bool,
	addLinkToPrimaryCol: PropTypes.bool,
	gridHeight: PropTypes.number,
	tableTestId: PropTypes.string,
	showGuidColumn: PropTypes.bool
};

CrudDataTable.defaultProps = {
	resourceNamePlural: null,
	disclaimer: null,
	resourceRoute: null,
	requestParams: null,
	noDataActionButtonConfig: null,
	footerActionButtonConfig: null,
	childResourceConfig: null,
	disableDelete: false,
	primaryColumnConfig: null,
	tableRowActionMenuCustomOptions: undefined,
	disableClone: false,
	paginationConfig: {},
	heading: null,
	customStartPathEndPoint: undefined,
	overrideDefaultColumns: false,
	defaultSortColumnKey: UPDATED_AT_KEY,
	showActions: true,
	addLinkToPrimaryCol: true,
	gridHeight: null,
	tableTestId: null,
	showGuidColumn: false
};
export default CrudDataTable;
