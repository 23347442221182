import React, {
	useEffect,
	useState,
	useCallback,
	useMemo,
	useContext
} from "react";
import PropTypes from "prop-types";
import { useFormikContext, useField } from "formik";
import { format } from "date-fns";
import FiltersToolbar from "../FiltersToolbar/FiltersToolbar";
import { Operations, useResourceClient } from "../../hooks/useResource";
import { dateRangeServerFormat, FILTER_TYPE } from "../../config/constants";
import useSkipInitChangeWatcher from "../../hooks/useSkipInitChangeWatcher";
import { getDateRangeByTimezone } from "../../utils/dateUtils";
import { AuthenticatedUserSettingsContext } from "../../context/AuthenticatedUserSettingsContext";
import { useResourceAsync } from "../../hooks/useResourceAsync";

// Convert API's Dimension data to Filter Toolbar Format
const convertDimensionsToFilterToolbarFormat = sliceList =>
	sliceList
		? Object.keys(sliceList)
				.filter(sliceKey => sliceKey !== "floor")
				.map(sliceKey => {
					return {
						label: sliceList[sliceKey],
						value: sliceKey,
						type: FILTER_TYPE.ALPHA_MULTIPLE
					};
				})
		: [];

// Convert API dimension value options to client side options
const transformDimensionValues = dimensions =>
	Object.keys(dimensions).map(dimensionKey => ({
		name: dimensions[dimensionKey],
		id: dimensionKey,
		isSortable: true
	}));

// Default to using last thirty days
const getDefaultDateRangeInUserTimezone = userTimezone => {
	const dates = getDateRangeByTimezone("previous30", userTimezone);
	return {
		start: format(dates.startDate, dateRangeServerFormat),
		end: format(dates.endDate, dateRangeServerFormat)
	};
};

const getDimensionOptionsDateRange = (userTimeZone, formikDateRange) => {
	// If a custom date range has been set, use it
	if (formikDateRange && formikDateRange[0] && formikDateRange[1]) {
		return {
			start: format(formikDateRange[0], dateRangeServerFormat),
			end: format(formikDateRange[1], dateRangeServerFormat)
		};
	}
	// Else use default
	return getDefaultDateRangeInUserTimezone(userTimeZone);
};

function FilterReports(props) {
	const {
		fixedFilters,
		filterValidationSchemaOptions,
		pageTitleBarHasTabs,
		allowedComparators
	} = props;
	const [selectedDimension, setSelectedDimension] = useState(null);

	const [field, , helpers] = useField("filters");
	const setFilter = helpers.setValue;
	const {
		values: { reportType, dateRange: formikDateRange }
	} = useFormikContext();

	const { userTimeZone } = useContext(AuthenticatedUserSettingsContext);
	const {
		data: dimensionList,
		error: dimensionError,
		isLoading: dimensionIsLoading,
		execute: fetchDimensionList
	} = useResourceAsync(
		// Do not make a request until we have a report type
		reportType
			? `reports/scheduled-reports/${reportType.toUpperCase()}/slices`
			: "",
		Operations.LIST
	);
	const onSelectDimension = useCallback(
		dimensionFromFilter => {
			if (dimensionFromFilter.value !== selectedDimension)
				setSelectedDimension(dimensionFromFilter.value);
		},
		[selectedDimension, setSelectedDimension]
	);

	const dimensionOptionsDateRange = getDimensionOptionsDateRange(
		userTimeZone,
		formikDateRange
	);

	const dimensionsUrl = `manage/metrics/search-dimension?dimension=${selectedDimension}&reportType=${reportType}&start=${dimensionOptionsDateRange.start}&end=${dimensionOptionsDateRange.end}`;

	const [
		rawDimensionValueOptionsData,
		valuesError,
		valuesIsLoading,
		fetchdimensionValueList
	] = useResourceClient(dimensionsUrl, Operations.LIST);

	const dimensionValuesOptions = useMemo(() => {
		if (selectedDimension && rawDimensionValueOptionsData) {
			return {
				[selectedDimension]: transformDimensionValues(
					rawDimensionValueOptionsData
				)
			};
		}
		return {};
	}, [selectedDimension, rawDimensionValueOptionsData]);

	useEffect(() => {
		if (selectedDimension) {
			fetchdimensionValueList();
		}
	}, [selectedDimension, fetchdimensionValueList]);

	const [shouldFetchDimensions, setShouldFetchDimensions] = useState(false);

	// Init data load sets the report type, which counts as the first "change" detected by the useEffect watcher. We need to take certain actions only on subsequent, post-init user changes to the form
	const [isReportTypeChangedByUser, dispatch] = useSkipInitChangeWatcher();
	useEffect(() => {
		if (reportType) {
			setShouldFetchDimensions(true);
			dispatch({ type: "VALUE_CHANGED" });
		}
	}, [reportType, dispatch]);

	// Update dimensions list any time reportType changes
	useEffect(() => {
		if (shouldFetchDimensions) {
			fetchDimensionList();
			setShouldFetchDimensions(false);
		}
	}, [shouldFetchDimensions, fetchDimensionList]);

	// Clear form values only after the initial change
	useEffect(() => {
		if (isReportTypeChangedByUser) {
			setFilter([]);
			dispatch({ type: "RESET" });
		}
	}, [isReportTypeChangedByUser, setFilter, dispatch]);

	return (
		<FiltersToolbar
			fixedFilters={fixedFilters}
			dimensionOptions={convertDimensionsToFilterToolbarFormat(dimensionList)}
			dimensionValuesOptions={dimensionValuesOptions}
			filters={field.value}
			setFilters={setFilter}
			onSelectDimension={onSelectDimension}
			fetchingStatus={{
				dimension: {
					isLoading: dimensionIsLoading,
					error: dimensionError
				},
				values: {
					isLoading: valuesIsLoading,
					error: valuesError
				}
			}}
			validationSchemaOptions={filterValidationSchemaOptions}
			typeaheadDetails={{
				requestEndpoint: dimensionsUrl,
				transformOptions: transformDimensionValues
			}}
			pageTitleBarHasTabs={pageTitleBarHasTabs}
			allowedComparators={allowedComparators}
		/>
	);
}
FilterReports.propTypes = {
	fixedFilters: PropTypes.arrayOf(PropTypes.node),
	filterValidationSchemaOptions: PropTypes.shape(),
	pageTitleBarHasTabs: PropTypes.bool,
	allowedComparators: PropTypes.arrayOf(PropTypes.string)
};
FilterReports.defaultProps = {
	fixedFilters: [],
	filterValidationSchemaOptions: undefined,
	pageTitleBarHasTabs: true,
	allowedComparators: undefined
};

export default FilterReports;
