import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import {
	GridToolbarContainer,
	GridToolbarQuickFilter,
	GridToolbarColumnsButton,
	GridToolbarDensitySelector
} from "@mui/x-data-grid-pro";

function DataGridTitle(props) {
	const { heading } = props;
	return (
		<Box
			style={{
				width: "100%",
				display: "flex",
				justifyContent: "left",
				alignItems: "left",
				marginLeft: "7px"
			}}
		>
			<Typography>{heading}</Typography>
		</Box>
	);
}

DataGridTitle.propTypes = { heading: PropTypes.string };
DataGridTitle.defaultProps = { heading: null };

function GridToolbarCustom(props) {
	const {
		toolbarMessage,
		setColumnsButtonEl,
		setFilterActive,
		tableActionButton
	} = props;
	return (
		<Box mt={1.2} mb={0.5}>
			<DataGridTitle heading={toolbarMessage} />
			<GridToolbarContainer sx={{ justifyContent: "space-between" }}>
				<GridToolbarQuickFilter />
				<Box>
					<GridToolbarColumnsButton
						ref={setColumnsButtonEl}
						onClick={() => {
							// TODO: I don't think this is used anywhere
							setFilterActive(false);
						}}
					/>
					<GridToolbarDensitySelector />
					{tableActionButton}
				</Box>
			</GridToolbarContainer>
		</Box>
	);
}

GridToolbarCustom.propTypes = {
	toolbarMessage: PropTypes.string,
	setColumnsButtonEl: PropTypes.func,
	setFilterActive: PropTypes.func,
	tableActionButton: PropTypes.node
};
GridToolbarCustom.defaultProps = {
	toolbarMessage: null,
	setColumnsButtonEl: () => {},
	setFilterActive: () => {}, // TODO: I don't think this is used anywhere
	tableActionButton: null
};

export default GridToolbarCustom;
