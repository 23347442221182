import React, { useEffect, useMemo, useState, useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Dialog } from "@mui/material";
import { Formik } from "formik";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import BaseDataGrid from "../BaseDataGrid/BaseDataGrid";
import LoadingBackdrop from "../LoadingBackdrop/LoadingBackdrop";
import { useResourceAsync, Operations } from "../../hooks/useResourceAsync";
import { SnackbarContext } from "../../context/SnackbarContext";
import lc from "./localeContent";
import SegmentForm from "./SegmentForm";
import commonLocaleContent from "../../screens/commonLocaleContent";
import { objectMap } from "../../utils/objectUtils";
import SegmentFileUpload from "./SegmentFileUpload";

const dialogTitleId = "segment-file-dialog-title";
const validationSchema = Yup.object().shape({
	name: Yup.string()
		.required(commonLocaleContent.REQUIRED_FIELD_WARNING)
		.max(250, "Segment name must be less than 250 characters")
});

function SegmentTable({ dataDistributionId }) {
	const { triggerNewSnackbarMessage } = useContext(SnackbarContext);
	const [fileUploadSegmentId, setFileUploadSegmentId] = useState("");

	const dataDistributionEndpoint = useMemo(
		() => `manage/dataDistributions/${dataDistributionId}/segments`,
		[dataDistributionId]
	);
	// TODO: Implement error handling
	const {
		data: indexData,
		isLoading: isIndexLoading,
		execute: requestIndexData
	} = useResourceAsync(dataDistributionEndpoint, Operations.LIST);

	// Fetch the data when the data distribution ID changes. Practically speaking, this should only happen once when the form loads
	useEffect(() => {
		if (dataDistributionId) {
			requestIndexData();
		}
	}, [dataDistributionId, requestIndexData]);

	const [initialFormValues, setInitialFormValues] = useState(null);
	const isCreate = useMemo(() => !initialFormValues?.id, [initialFormValues]);

	const formSubmitConfig = useMemo(() => {
		const endpoint = "manage/segments";
		const operation = isCreate ? Operations.CREATE : Operations.UPDATE;
		const params = isCreate ? undefined : { resourceId: initialFormValues?.id };
		return { endpoint, operation, params };
	}, [isCreate, initialFormValues]);

	const {
		isLoading: isFormDataSubmitting,
		execute: submitFormData
	} = useResourceAsync(
		formSubmitConfig.endpoint,
		formSubmitConfig.operation,
		formSubmitConfig.params
	);

	const [isFormModalOpen, setIsFormModalOpen] = useState(false);

	// Open the modal anytime we set the initial form values
	useEffect(() => {
		if (initialFormValues) {
			setIsFormModalOpen(true);
		}
	}, [initialFormValues]);

	const openSegmentAddForm = () => {
		setInitialFormValues({ name: "" });
	};

	const closeModal = (event, reason) => {
		if (reason === "backdropClick") {
			return;
		}
		setIsFormModalOpen(false);
		setInitialFormValues(null);
	};

	const openFileUploadForm = id => {
		setFileUploadSegmentId(id);
	};

	const columns = useMemo(() => {
		return [
			{ field: "name", headerName: "Segment Name", flex: 1 },
			{
				field: "upload",
				headerName: "Upload",
				renderCell: ({ row }) => (
					<Button
						color="primary"
						startIcon={<FileUploadIcon />}
						onClick={() => openFileUploadForm(row.segmentId)}
						style={{ minWidth: "auto", padding: 1 }}
						aria-label={lc.FILE_UPLOAD_ARIA_LABEL}
					/>
				),
				sortable: false,
				filterable: false,
				flex: 0
			}
		];
	}, []);

	return (
		<Paper>
			<LoadingBackdrop isOpen={isIndexLoading || isFormDataSubmitting} />
			<BaseDataGrid
				rows={indexData}
				columns={columns}
				paginationConfig={{ defaultRowsPerPage: 10 }}
				defaultSortConfig={{ field: "name", direction: "asc" }}
				gridHeight={450}
				disableColumnSelector
				disableDensitySelector
				tableActionButton={
					<Button
						variant="contained"
						onClick={openSegmentAddForm}
						type="button"
						color="secondary"
						startIcon={<AddIcon />}
					>
						{lc.ADD_SEGMENT_BUTTON_LABEL}
					</Button>
				}
				gridAriaLabel={lc.GRID_ARIA_LABEL}
			/>
			<Dialog
				open={isFormModalOpen}
				onClose={closeModal}
				aria-labelledby={dialogTitleId}
				fullWidth
				maxWidth="sm"
				closeAfterTransition={false} // Per https://github.com/mui/material-ui/issues/43106#issuecomment-2404007826
			>
				<Formik
					enableReinitialize
					initialValues={initialFormValues}
					validationSchema={validationSchema}
					onSubmit={async (formValues, formikActions) => {
						const { setErrors } = formikActions;
						// Add the data distribution ID to all requests
						const submitData = { ...formValues, dataDistributionId };
						const { data, error } = await submitFormData({ data: submitData });
						if (error) {
							const { detail, message } = error;
							// Field level errors
							if (detail) {
								// Join the array of errors into a single string
								setErrors(objectMap(detail, value => value.join(" - ")));
							}
							// Form level errors
							else if (message) {
								triggerNewSnackbarMessage({ message, severity: "error" });
							}
						}
						if (data) {
							requestIndexData();
							triggerNewSnackbarMessage({
								message: lc.SAVE_SUCCESS_MESSAGE(data.name),
								severity: "success"
							});
							closeModal();
						}
					}}
				>
					<SegmentForm isCreate={isCreate} closeModal={closeModal} />
				</Formik>
			</Dialog>
			<SegmentFileUpload
				setSegmentId={setFileUploadSegmentId}
				segmentId={fileUploadSegmentId}
			/>
		</Paper>
	);
}

SegmentTable.propTypes = {
	dataDistributionId: PropTypes.string.isRequired
};

export default SegmentTable;
