import React from "react";
import CrudDataTable from "../../components/CrudDataTable/CrudDataTable";
import { ACTION_MENU_ITEM_TYPES } from "../../components/GenericTableRowActionMenu/GenericTableRowActionMenu";
import lc from "./localeContent";

const RESOURCE_ENDPOINT = "reports/scheduled-reports";

const RESOURCE_MANUAL_RUN_ENDPOINT = id => `${RESOURCE_ENDPOINT}/${id}/run`;

const tableRowActionMenuCustomOptions = [
	{
		key: "MANUAL_RUN",
		type: ACTION_MENU_ITEM_TYPES.confirmPostResource,
		label: lc.SCHEDULED_REPORT_MANUAL_RUN_BUTTON_TEXT,
		accessibilityLabel: lc.SCHEDULED_REPORT_MANUAL_RUN_ACCESSIBILITY_TEXT,
		title: lc.SCHEDULED_REPORT_MANUAL_RUN_RESOURCE_MODAL.TITLE,
		successMessage:
			lc.SCHEDULED_REPORT_MANUAL_RUN_RESOURCE_MODAL.SUCCESS_MESSAGE,
		description: lc.SCHEDULED_REPORT_MANUAL_RUN_RESOURCE_MODAL.DESCRIPTION,
		confirmLabel:
			lc.SCHEDULED_REPORT_MANUAL_RUN_RESOURCE_MODAL.ACTION_BUTTON_LABEL_CONFIRM,
		getResourceEndpoint: RESOURCE_MANUAL_RUN_ENDPOINT,
		permissionRequired: "REPORTING_SCHEDULED_RUN_MANUALLY"
	}
];

function ScheduledReport() {
	return (
		<CrudDataTable
			resourceName={lc.SCHEDULED_REPORT_RESOURCE_NAME}
			columnsConfig={[]}
			resourceEndpoint={RESOURCE_ENDPOINT}
			resourceRoute="scheduled-reports"
			requestParams={{
				reportTypes: ["network", "rtb", "campaign"]
			}}
			primaryColumnConfig={{
				name: lc.COLUMN_HEADER_FILE_NAME,
				key: "fileName"
			}}
			paginationConfig={{ defaultRowsPerPage: 5 }}
			heading={lc.SCHEDULED_REPORTS_TABLE_HEADING}
			disableClone
			tableRowActionMenuCustomOptions={tableRowActionMenuCustomOptions}
			gridHeight={450}
			tableTestId="scheduledReports"
			gridAriaLabel={lc.SCHEDULED_REPORTS_TABLE_CAPTION}
		/>
	);
}

export default ScheduledReport;
