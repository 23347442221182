export default {
	ACTION_BUTTON_LABEL_EDIT: "Edit",
	ACTION_BUTTON_LABEL_CLONE: "Clone",
	ACTION_BUTTON_LABEL_DELETE: "Delete",
	DELETE_RESOURCE_SUCCESS_MESSAGE: resourceName =>
		`${resourceName} was successfully deleted`,
	DELETE_RESOURCE_MODAL: {
		TITLE: ({ resourceName }) => `Delete ${resourceName ?? ""}`,
		DESCRIPTION: ({ itemName, deleteAssociatedResourceWarning }) =>
			`You are about to delete ${itemName}${
				deleteAssociatedResourceWarning
					? ` as well as all associated ${deleteAssociatedResourceWarning}`
					: ""
			}. This action cannot be undone.`,
		ACTION_BUTTON_LABEL_CONFIRM: "Delete"
	},
	ACTION_BUTTON_LABEL_ADD_CHILD: ({ childName }) => `Add ${childName}`,
	ACTION_BUTTON_LABEL_VIEW_CHILD: ({ childName, childPluralName }) => {
		return `View ${childPluralName ?? `${childName}s`}`;
	},
	COLUMN_HEADERS: {
		NAME: "Name",
		CREATED_AT: "Created",
		UPDATED_AT: "Updated"
	},
	ACCESSIBLE_TABLE_DESCRIPTION: pluralResourceName =>
		`${pluralResourceName} table`
};
