import React, { useState, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { DataGridPro as DataGrid } from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import { AuthenticatedUserSettingsContext } from "../../context/AuthenticatedUserSettingsContext";
import CustomFooterStatusComponent from "./CustomFooterStatusComponent";
import CustomPagination from "./CustomPagination";
import GridToolbarCustom from "./GridToolbarCustom";

function BaseDataGrid(props) {
	const {
		disclaimer,
		columns,
		rows,
		paginationConfig,
		heading,
		gridHeight,
		tableTestId,
		defaultSortConfig,
		tableActionButton,
		disableColumnSelector,
		disableDensitySelector,
		gridAriaLabel,
		footerActionButton
	} = props;

	// Get defaults from context
	const { defaultDensity } = useContext(AuthenticatedUserSettingsContext);

	// Set up the DataGrid sorting model using defaults from props
	const [sortModel, setSortModel] = useState([
		{
			field: defaultSortConfig.field,
			sort: defaultSortConfig.direction || "desc"
		}
	]);

	const [pageSize, handlePageSizeChange] = React.useState(
		paginationConfig.defaultRowsPerPage || 10
	);

	const rowsPerPageOptions = useMemo(() => {
		const pageOptions = [paginationConfig.defaultRowsPerPage || 10, 20, 50];
		const rowCount = rows?.length || 0;
		return rowCount <= 50 ? pageOptions : [...pageOptions, rowCount];
	}, [paginationConfig.defaultRowsPerPage, rows]);

	const [columnsButtonEl, setColumnsButtonEl] = React.useState(null);

	// Add keys for react forEach rendering
	const rowsWithKeys = useMemo(() => {
		return (rows || []).map(data => {
			data.key = `data-grid-key-${data.id}`;
			return data;
		});
	}, [rows]);

	return (
		<Box
			sx={{
				height: gridHeight || 720,
				my: 3,
				px: 2
			}}
			data-testid={tableTestId || "indexTableId"}
		>
			<Box sx={{ display: "flex", height: "100%" }}>
				<DataGrid
					disableColumnFilter
					aria-label={gridAriaLabel}
					disableSelectionOnClick
					rows={rowsWithKeys}
					columns={columns}
					pageSize={pageSize}
					rowsPerPageOptions={rowsPerPageOptions}
					onSortModelChange={setSortModel}
					sortModel={sortModel}
					sortingOrder={["desc", "asc"]}
					pagination
					onPageSizeChange={handlePageSizeChange}
					density={defaultDensity}
					components={{
						Footer: CustomFooterStatusComponent,
						Pagination: CustomPagination,
						MoreActionsIcon: AddIcon,
						Toolbar: GridToolbarCustom
					}}
					componentsProps={{
						footer: { footerMessage: disclaimer, footerActionButton },
						toolbar: {
							toolbarMessage: heading,
							setColumnsButtonEl,
							tableActionButton
						},
						panel: {
							anchorEl: columnsButtonEl
						}
					}}
					disableColumnSelector={disableColumnSelector}
					disableDensitySelector={disableDensitySelector}
					sx={{
						border: "none",
						".MuiDataGrid-columnHeaders": {
							backgroundColor: "primary.800",
							color: "common.white"
						},
						".MuiDataGrid-row:nth-of-type(even)": {
							backgroundColor: "secondary.300"
						},
						".MuiDataGrid-sortIcon": {
							color: "common.white"
						},
						".MuiDataGrid-menuIconButton": {
							color: "common.white"
						},
						".MuiDataGrid-columnHeaderTitle": {
							marginLeft: "10px"
						}
					}}
				/>
			</Box>
		</Box>
	);
}

BaseDataGrid.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	rows: PropTypes.arrayOf(PropTypes.shape()),
	disclaimer: PropTypes.string,
	paginationConfig: PropTypes.shape({
		defaultRowsPerPage: PropTypes.number
	}),
	heading: PropTypes.string,
	gridHeight: PropTypes.number,
	tableTestId: PropTypes.string,
	defaultSortConfig: PropTypes.shape({
		field: PropTypes.string.isRequired,
		direction: PropTypes.string
	}).isRequired,
	tableActionButton: PropTypes.node,
	disableColumnSelector: PropTypes.bool,
	disableDensitySelector: PropTypes.bool,
	gridAriaLabel: PropTypes.string.isRequired,
	footerActionButton: PropTypes.node
};
BaseDataGrid.defaultProps = {
	disclaimer: null,
	rows: [],
	paginationConfig: {},
	heading: null,
	gridHeight: null,
	tableTestId: null,
	tableActionButton: null,
	disableColumnSelector: false,
	disableDensitySelector: false,
	footerActionButton: null
};

export default BaseDataGrid;
