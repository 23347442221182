import React, {
	useContext,
	useMemo,
	useState,
	useCallback,
	useEffect
} from "react";
import PropTypes from "prop-types";
import { Paper } from "@mui/material";
import { DataGridPro as DataGrid } from "@mui/x-data-grid-pro";
import orderBy from "lodash/orderBy";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import { format } from "date-fns";
import { stringify } from "qs";
import { useFormikContext } from "formik";

import { kebabCase } from "lodash";
import GridToolbarCustom from "../GridToolbarCustom";
import { AuthorizationContext } from "../../../context/AuthorizationContext";
import {
	METRIC_TYPES,
	REPORTING_METRIC,
	getMetricsConfigByType
} from "../metricsConfig";
import {
	REPORT_TYPE_KEY,
	SLICE_CONFIG_KEY,
	DATE_RANGE_KEY,
	FILTER_KEY,
	TIME_ZONE_KEY
} from "../constants";
import {
	REPORT_TYPE_OPTIONS_ENUM,
	dateRangeServerFormat
} from "../../../config/constants";
import { CONDITIONAL_REPORTING_ITEMS } from "../../../screens/ScheduledReports/config";
import { useResourceAsync, Operations } from "../../../hooks/useResourceAsync";
import { AuthContext } from "../../../context/AuthContext";
import clc from "../../../screens/commonLocaleContent";
import lc from "../localeContent";
import { SnackbarContext } from "../../../context/SnackbarContext";
import { BrandingContext } from "../../../context/BrandingContext";
import ExpansionCell from "../ExpansionCell";
import LinkToNewTab from "../../Routing/LinkToNewTab";
import { AuthenticatedUserSettingsContext } from "../../../context/AuthenticatedUserSettingsContext";
import { reduceByComparator } from "../reportingUtils";

const getWindowsHeight = () => {
	if (window) {
		return window.innerHeight;
	}
	return 1000;
};
// Used to convert the object map to an array of options objects
const transformDimensionsResponse = data =>
	Object.entries(data).map(([key, value]) => ({
		id: key,
		label: value,
		condition: CONDITIONAL_REPORTING_ITEMS[key]
			? CONDITIONAL_REPORTING_ITEMS[key]
			: null
	}));
const REQUEST_DIMENSION_OPTIONS_CONFIG = {
	transformResponse: transformDimensionsResponse
};

const FONT_CHARACTER_PIXELS = 7;
const FONT_THOUSANDS_SEPARATOR_PIXELS = 5;
const CELL_PADDING_PIXELS = 24;
export const DEFAULT_MIN_WIDTH = 50;
export const GROUP_COLUMN_KEY = "group";

// Maintain a list of keys that should not be sorted by clicking column headers
const SKIP_CLIENT_SIDE_SORT_CONFIG = {
	hour: true,
	day: true
};

const hasPermissions = (columnItem, authorizationContext) => {
	if (columnItem.permissionsRequired) {
		return columnItem.permissionsRequired.every(permission =>
			authorizationContext.hasPermission(permission)
		);
	}
	return true;
};

const getInitialSortByReportType = reportType => {
	let field;
	switch (reportType) {
		case REPORT_TYPE_OPTIONS_ENUM.rtb.value:
			field = REPORTING_METRIC.CLOSE_REVENUE.field;
			break;
		case REPORT_TYPE_OPTIONS_ENUM.network.value:
		case REPORT_TYPE_OPTIONS_ENUM.campaign.value:
		default:
			field = REPORTING_METRIC.GROSS_REVENUE.field;
			break;
	}
	return [
		{
			field,
			sort: "desc"
		}
	];
};

// Create a config object with default fields marked as visible
const buildColumnsVisibilityFilter = columnsConfig =>
	columnsConfig.reduce(
		(agg, column) => ({
			...agg,
			// Dimension columns are always shown, metrics are shown if they are default metrics
			[column.field]: !column.isMetricColumn || Boolean(column.default)
		}),
		{}
	);

const transformColumnVisibilityModelToQueryParms = (
	columnsConfig,
	columnVisibilityModel
) =>
	columnsConfig.reduce((agg, { field, hideable }) => {
		const isHideable = hideable !== false;
		// When all metrics are shown using MUI datagrid "Show All", mui datagrid sets to an empty object
		const isVisible = columnVisibilityModel[field] !== false;
		if (isHideable && isVisible) {
			agg.push(field);
		}
		return agg;
	}, []);

// Exporting for testing
export const addDataAwareMinWidthsToColumnsConfig = (columns, data) => {
	const getFieldTypeAdjustment = dataType => {
		const FIELD_TYPE_ADJUSTMENTS = {
			[METRIC_TYPES.CURRENCY]: 2, // Currency fields round to nearest cent so have two extra characters
			[METRIC_TYPES.PERCENT]: 1 // Percents have a percentage symbol added by a formatter
		};
		return FIELD_TYPE_ADJUSTMENTS[dataType] || 0;
	};

	const maxDataLengthByColumn = data.reduce(
		(currentCounts, dataRow) =>
			columns.reduce((agg, column) => {
				// Use current length or 0 to avoid NaN
				const currentMaxColumnLength = currentCounts[column.field] || 0;
				const columnLength = Math.round(dataRow[column.field]).toString()
					.length;
				return {
					...agg,
					[column.field]: Math.max(currentMaxColumnLength, columnLength)
				};
			}, {}),
		{}
	);
	return columns.map(column => {
		// Respect any custom min widths that have been set
		const defaultMinWidth = column.minWidth || 50;
		const columnDataLength = maxDataLengthByColumn[column.field] || 0;
		const commaAdjustment =
			Math.floor(Math.max(columnDataLength - 1, 1) / 3) *
			FONT_THOUSANDS_SEPARATOR_PIXELS;
		const totalCharacterCount =
			columnDataLength + getFieldTypeAdjustment(column.dataType);
		const dataAwareWidth =
			totalCharacterCount * FONT_CHARACTER_PIXELS +
			commaAdjustment +
			CELL_PADDING_PIXELS;
		// Get max of the default column width or the data-aware width
		return {
			...column,
			minWidth: Math.max(defaultMinWidth, dataAwareWidth)
		};
	});
};

const getRowExpansionKey = (selectedSlices, sliceKey) => {
	const sliceIndex = selectedSlices.findIndex(slice => slice === sliceKey);
	if (sliceIndex + 1 === selectedSlices.length) return null;
	return selectedSlices[sliceIndex + 1];
};

/**
 * Creates a "-" separated id from item and parent ids
 * @param {Object} filters row parent id filters
 * @param {string} itemId row id
 * @returns string id
 */

const buildUniqueId = filters => {
	const filterIds = Object.entries(filters).map(([, filterId]) => filterId);
	return [...filterIds].join("-");
};

export const calculateDerivedValues = (
	columnsConfig,
	companyConfig,
	rowData = {}
) =>
	columnsConfig.reduce((agg, column) => {
		if (column.getDerivedValue) {
			return {
				...agg,
				[column.field]: column.getDerivedValue(rowData, companyConfig)
			};
		}
		return agg;
	}, {});

const checkNullOrReplaceValue = value => {
	switch (value) {
		case "null":
			return lc.NOT_AVAILABLE;
		case lc.CELL_VALUE_REPLACE:
			return lc.NOT_PROVIDED;
		default:
			return value;
	}
};

export const buildGroupedTableData = (
	selectedSliceIds,
	sliceKey,
	parentFilters = {}
) => rowData => {
	const rowFilters = { ...parentFilters, [sliceKey]: rowData.group };
	return {
		...rowData,
		groupKey: sliceKey,
		groupId: rowData.groupId,
		[sliceKey]:
			rowData.groupPrettyName || checkNullOrReplaceValue(rowData.group),
		id: buildUniqueId(rowFilters),
		expansionColumnId: getRowExpansionKey(selectedSliceIds, sliceKey),
		filters: rowFilters
	};
};

const isGroupAndFilter = sliceConfig => !isEmpty(sliceConfig);

const getDimensionEditURL = (dimensionType, id) => {
	const overrideDimensions = {
		buyer: "advertisers",
		buyers: "bidders",
		media: "media" //  media  url do not need s at end
	};
	const urlDimension = overrideDimensions[dimensionType] || `${dimensionType}s`;
	return `/dashboard/${kebabCase(urlDimension)}/${id}`;
};

// Helper function for generating the link
const generateLinkIfGroupIdIsFound = params => {
	if (!params.row.groupId || !params.value) return undefined;
	return (
		<LinkToNewTab
			to={getDimensionEditURL(params.field, params.row.groupId)}
			text={params.value}
		/>
	);
};

// Refactored function
const renderGroupCell = (params, index) => {
	const link = generateLinkIfGroupIdIsFound(params);

	// Render ExpansionCell from 2nd group onwards
	if (index > 0) {
		return (
			<ExpansionCell
				colDef={params.colDef}
				row={params.row}
				value={link || params.value}
			/>
		);
	}

	return link;
};

/**
 * Add either
 * 	- the "All" spacer column that has no data or column label
 *  - the columns for selected slices
 */
const buildGroupColumns = (sliceConfig, currentDimensions) => {
	if (isGroupAndFilter(sliceConfig)) {
		if (isEmpty(currentDimensions)) {
			return [];
		}
		return sliceConfig.map((sliceId, index) => ({
			field: sliceId,
			headerName: currentDimensions.find(option => option.id === sliceId).label,
			minWidth: 100,
			flex: 1,
			renderCell: params => renderGroupCell(params, index),
			sortable: false,
			hideable: false
		}));
	}
	return [
		{
			field: GROUP_COLUMN_KEY,
			headerName: "",
			flex: 1,
			minWidth: 75,
			sortable: false,
			hideable: false
		}
	];
};

const transformFilterAndGroupResponse = (
	data,
	columnsConfig,
	companyConfig
) => {
	return data?.map(({ data: metricsData, ...metaData }) => ({
		...metricsData,
		...calculateDerivedValues(columnsConfig, companyConfig, metricsData),
		...metaData
	}));
};

export function getHeaderNameByDataType(columnName, dataType) {
	if (dataType === METRIC_TYPES.CURRENCY) {
		return `${columnName} ($)`;
	}
	return columnName;
}

function ReportingTable(props) {
	const {
		rawData,
		activeReportConfig,
		requestGroupedData,
		defaultUserMetrics,
		defaultUserDimensions
	} = props;
	const { triggerNewSnackbarMessage } = useContext(SnackbarContext);
	const authorizationContext = useContext(AuthorizationContext);
	const { companyConfig } = useContext(BrandingContext);
	const { isDemandClient } = useContext(AuthContext);

	const {
		[REPORT_TYPE_KEY]: activeReportType,
		[SLICE_CONFIG_KEY]: activeReportSliceConfig
	} = activeReportConfig;

	const [dimensionOptions, setDimensionOptions] = useState([]);
	const { execute: requestDimensionOptions } = useResourceAsync(
		`reports/scheduled-reports/${activeReportType.toUpperCase()}/slices`,
		Operations.LIST,
		REQUEST_DIMENSION_OPTIONS_CONFIG
	);

	const [activeReportTypeChanged, setActiveReportTypeChanged] = useState(false);
	useEffect(() => {
		setActiveReportTypeChanged(true);
	}, [activeReportType]);

	// Calculate the derived values based on report-type-specific metrics config objects.
	const rawDataWithDerivedValues = useMemo(() => {
		const metricsColumns = getMetricsConfigByType(activeReportType);
		return transformFilterAndGroupResponse(
			rawData,
			metricsColumns,
			companyConfig
		);
	}, [activeReportType, rawData, companyConfig]);

	// Add row state based on slice config
	const tableData = useMemo(() => {
		if (isGroupAndFilter(activeReportSliceConfig)) {
			return rawDataWithDerivedValues.map(
				buildGroupedTableData(
					activeReportSliceConfig,
					activeReportSliceConfig[0],
					{}
				)
			);
		}
		return [
			{
				...rawDataWithDerivedValues[0],
				[GROUP_COLUMN_KEY]: lc.ALL_LABEL,
				id: "all"
			}
		];
	}, [activeReportSliceConfig, rawDataWithDerivedValues]);

	const [cachedGroupedTableData, setCachedGroupedTableData] = useState({});
	const [expandedRows, setExpandedRows] = useState({});

	// Whenever a new activeReportsSliceConfig is applied, reset expanded rows and grouped data
	useEffect(() => {
		setCachedGroupedTableData({});
		setExpandedRows({});
	}, [activeReportConfig]);

	// Setup sorting
	const [sortModel, setSortModel] = useState(
		getInitialSortByReportType(REPORT_TYPE_OPTIONS_ENUM.network.value)
	);
	useEffect(() => {
		setSortModel(getInitialSortByReportType(activeReportType));
	}, [activeReportType]);

	// Setup column configuration
	const columnsConfig = useMemo(() => {
		const groupColumns = buildGroupColumns(
			activeReportSliceConfig,
			dimensionOptions
		);

		const defaultMetrics =
			defaultUserMetrics && defaultUserMetrics[activeReportType];

		let metricsColumnsByType = getMetricsConfigByType(activeReportType, {
			isDemandClient
		})
			// Filter out columns user doesn't have permission to access
			.filter(column => hasPermissions(column, authorizationContext))
			// Enable sorting if dimensions are applied
			.map(column => {
				// Use the hard coded default options as the default
				let isDefaultMetric = column.default;
				// If user has defaults set, use them
				if (defaultMetrics && defaultMetrics.length) {
					isDefaultMetric = defaultMetrics.includes(column.field);
				}
				return {
					...column,
					default: isDefaultMetric,
					isMetricColumn: true,
					sortable: isGroupAndFilter(activeReportSliceConfig),
					headerName: getHeaderNameByDataType(column.name, column.dataType)
				};
			});

		// Adjust minWidths of the columns based on data
		// We don't need to track the nested grouped data as any grouped data should add up to parent data so is never larger/wider
		metricsColumnsByType = addDataAwareMinWidthsToColumnsConfig(
			metricsColumnsByType,
			rawDataWithDerivedValues,
			FONT_CHARACTER_PIXELS
		);
		return [...groupColumns, ...metricsColumnsByType];
	}, [
		activeReportType,
		activeReportSliceConfig,
		dimensionOptions,
		authorizationContext,
		rawDataWithDerivedValues,
		defaultUserMetrics,
		isDemandClient
	]);

	// Setup default column show/hide
	const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

	useEffect(() => {
		if (activeReportType && activeReportTypeChanged) {
			const refreshDimensionOptions = async () => {
				const { data } = await requestDimensionOptions({});
				setDimensionOptions(data || []);
			};

			// clearing dimensions here so that old dimensions are not used while new list is being fetched asynchronously
			setDimensionOptions([]);
			setActiveReportTypeChanged(false);
			setColumnVisibilityModel(buildColumnsVisibilityFilter(columnsConfig));
			refreshDimensionOptions();
		}
	}, [
		activeReportType,
		activeReportTypeChanged,
		columnsConfig,
		requestDimensionOptions
	]);

	// Toggle the clicked id within its group
	const handleRowExpansionClick = useCallback(
		(rowId, expansionColumnId, shouldExpand, rowFilters = {}) => async () => {
			if (shouldExpand) {
				const {
					[REPORT_TYPE_KEY]: reportType,
					[DATE_RANGE_KEY]: [startDate, endDate],
					[SLICE_CONFIG_KEY]: sliceConfig,
					[FILTER_KEY]: filtersConfig,
					[TIME_ZONE_KEY]: tz
				} = activeReportConfig;

				const filters = reduceByComparator("=", filtersConfig);
				const negativeFilters = reduceByComparator("!=", filtersConfig);

				const requestFilters = {
					...filters,
					...rowFilters
				};

				const { data, error } = await requestGroupedData({
					requestParams: {
						reportType,
						start: format(startDate, dateRangeServerFormat),
						end: format(endDate, dateRangeServerFormat),
						tz,
						group: expansionColumnId,
						filters: requestFilters,
						negativeFilters
					}
				});

				// Transform result
				const groupedTableData = data
					? transformFilterAndGroupResponse(
							data.data,
							columnsConfig,
							companyConfig
					  )
							// Add row state to the child rows based on slice config and parent row filters
							.map(
								buildGroupedTableData(
									sliceConfig,
									expansionColumnId,
									rowFilters
								)
							)
					: [];
				if (error) {
					triggerNewSnackbarMessage({
						message: clc.GENERIC_SERVER_ERROR_MESSAGE,
						severity: "error"
					});
				}

				setCachedGroupedTableData(existing => ({
					...existing,
					[rowId]: groupedTableData
				}));
			}

			setExpandedRows(existing => ({ ...existing, [rowId]: shouldExpand }));
		},
		[
			activeReportConfig,
			requestGroupedData,
			columnsConfig,
			triggerNewSnackbarMessage,
			companyConfig
		]
	);

	// Recursively builds an array of visible table rows by checking expanded state and concatenating child rows if parent row is expanded
	const getChildRows = useCallback(
		rowId => {
			const childRows = cachedGroupedTableData[rowId] || [];
			const childGroupKey = first(childRows)?.groupKey;
			const shouldSkipSorting = SKIP_CLIENT_SIDE_SORT_CONFIG[childGroupKey];
			const sortedChildRows = shouldSkipSorting
				? childRows
				: orderBy(
						childRows || [],
						[sortModel.map(({ field }) => field)],
						[sortModel.map(({ sort }) => sort)]
				  );
			if (!expandedRows[rowId]) {
				return [];
			}
			return [
				...sortedChildRows.reduce(
					(agg, row) => [...agg, row, ...getChildRows(row.id)],
					[]
				)
			];
		},
		[cachedGroupedTableData, expandedRows, sortModel]
	);

	// Get all visible table rows using local react expansion state, and then add an expansion state flag to each row of data for consumption by the table.
	const expandableTableData = useMemo(() => {
		const shouldSkipSorting =
			SKIP_CLIENT_SIDE_SORT_CONFIG[activeReportSliceConfig[0]];
		const sortedTableData = shouldSkipSorting
			? tableData
			: orderBy(
					tableData,
					[sortModel.map(({ field }) => field)],
					[sortModel.map(({ sort }) => sort)]
			  );
		return sortedTableData
			.reduce((agg, row) => [...agg, row, ...getChildRows(row.id)], [])
			.map(row => {
				const isExpanded = expandedRows[row.id];
				return {
					...row,
					onClick: handleRowExpansionClick(
						row.id,
						row.expansionColumnId,
						!isExpanded,
						row.filters
					),
					isExpanded
				};
			});
	}, [
		tableData,
		getChildRows,
		expandedRows,
		handleRowExpansionClick,
		sortModel,
		activeReportSliceConfig
	]);
	const { values } = useFormikContext();
	// Export Reports params configuration
	const exportReportParams = useMemo(() => {
		const { filters, dateRange, sliceConfig, tz } = values;
		const { reportType } = activeReportConfig;

		const positiveFilters = reduceByComparator("=", filters);
		const negativeFilters = reduceByComparator("!=", filters);

		return {
			startDate: format(new Date(dateRange[0]), dateRangeServerFormat),
			endDate: format(new Date(dateRange[1]), dateRangeServerFormat),
			filters: positiveFilters,
			negativeFilters,
			metrics: transformColumnVisibilityModelToQueryParms(
				columnsConfig,
				columnVisibilityModel
			),
			slice: sliceConfig,
			reportType,
			timeZone: tz
		};
	}, [activeReportConfig, columnVisibilityModel, columnsConfig, values]);

	const authContext = useContext(AuthContext);
	const { defaultDensity } = useContext(AuthenticatedUserSettingsContext);
	const { companyId } = authContext;

	// Scheduled Reports link configuration
	const scheduledReportsLinkRoute = useMemo(() => {
		const { sliceConfig, filters } = values;
		const scheduledReportsRoute = "/dashboard/scheduled-reports/INIT";
		const { reportType } = activeReportConfig;
		const positiveFilters = reduceByComparator("=", filters);
		const negativeFilters = reduceByComparator("!=", filters);

		const queryParams = stringify({
			companyId,
			reportType,
			dimensions: sliceConfig,
			filters: positiveFilters,
			negativeFilters,
			metrics: transformColumnVisibilityModelToQueryParms(
				columnsConfig,
				columnVisibilityModel
			)
		});

		return `${scheduledReportsRoute}?${queryParams}`;
	}, [
		activeReportConfig,
		companyId,
		columnsConfig,
		columnVisibilityModel,
		values
	]);
	// Table should only be one row high when rendering single row ("All" view)
	// The getWindowsHeight method will automatically get the browser's height and will apply here. in case there is no window method then it will return the default height (1000).
	const tableHeight = expandableTableData.length > 1 ? getWindowsHeight() : 210;
	return (
		<Paper sx={{ height: tableHeight, my: 3 }}>
			<div style={{ display: "flex", height: "100%" }}>
				<DataGrid
					rows={expandableTableData}
					columns={columnsConfig}
					columnVisibilityModel={columnVisibilityModel}
					onColumnVisibilityModelChange={newModel =>
						setColumnVisibilityModel(newModel)
					}
					localeText={{
						toolbarColumns: lc.COLUMNS_TOOLBAR_LABEL,
						toolbarColumnsLabel: lc.SELECT_METRICS_ARIA_LABEL,
						columnsPanelTextFieldLabel: lc.COLUMNS_PANEL_TEXTFIELD_PLACEHOLDER,
						columnsPanelTextFieldPlaceholder: ""
					}}
					disableSelectionOnClick
					disableColumnFilter
					disableColumnPinning
					disableColumnReorder
					hideFooter
					components={{
						Toolbar: GridToolbarCustom
					}}
					componentsProps={{
						toolbar: {
							scheduledReportsLinkRoute,
							dimensionOptions,
							exportReportParams,
							defaultUserDimensions
						}
					}}
					sortingMode="server"
					onSortModelChange={setSortModel}
					sortModel={sortModel}
					sortingOrder={["desc", "asc"]}
					density={defaultDensity}
				/>
			</div>
		</Paper>
	);
}

ReportingTable.propTypes = {
	rawData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	activeReportConfig: PropTypes.shape().isRequired,
	requestGroupedData: PropTypes.func.isRequired,
	defaultUserMetrics: PropTypes.shape(),
	defaultUserDimensions: PropTypes.shape()
};
ReportingTable.defaultProps = {
	defaultUserMetrics: {},
	defaultUserDimensions: {}
};

export default ReportingTable;
