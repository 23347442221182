import {
	differenceInDays,
	endOfMonth,
	format,
	isToday,
	parse,
	startOfMonth,
	sub,
	subMonths
} from "date-fns";
import {
	REPORT_TYPE_KEY,
	DATE_RANGE_KEY,
	FILTER_KEY,
	SLICE_CONFIG_KEY,
	TIME_ZONE_KEY
} from "./constants";
import {
	dateRangeServerFormat,
	dateTimeServerFormat
} from "../../config/constants";
import { DATE_RANGE, todayInTimezone } from "../../utils/dateUtils";

export const reduceByComparator = (comparator, filtersConfig) =>
	filtersConfig
		.filter(filter => filter.comparator === comparator)
		.reduce(
			(agg, filter) => ({
				...agg,
				[filter.dimension]: filter.value
			}),
			{}
		);

export const buildPerformanceDataRequestParams = reportConfig => {
	const {
		[REPORT_TYPE_KEY]: reportType,
		[DATE_RANGE_KEY]: [startDate, endDate],
		[FILTER_KEY]: filtersConfig,
		[TIME_ZONE_KEY]: tz
	} = reportConfig;
	// Get formatted date string from datepicker
	const start = format(startDate, dateRangeServerFormat);
	const end = format(endDate, dateRangeServerFormat);
	const filters = reduceByComparator("=", filtersConfig);
	const negativeFilters = reduceByComparator("!=", filtersConfig);
	const perfParams = {
		requestParams: {
			reportType,
			start,
			end,
			tz,
			filters,
			negativeFilters
		}
	};
	return perfParams;
};

// The filter-and-group endpoint requires a "group" parameter in addition to the performance data request parameters
export const buildGroupandFilterRequestParams = reportConfig => {
	const { [SLICE_CONFIG_KEY]: sliceConfig } = reportConfig;

	if (sliceConfig) {
		const filterAndGroupParams = {
			requestParams: {
				...buildPerformanceDataRequestParams(reportConfig).requestParams,
				group: sliceConfig[0] // This is the page level request so we use the first slice
			}
		};
		return filterAndGroupParams;
	}
	return {};
};

export const getReportingPreviousDateRange = async (
	{ start, end, tz },
	dateRangeType,
	requestParams,
	requestLastProcessedDate
) => {
	const today = todayInTimezone(tz);
	const sourceStartDate = parse(start, dateRangeServerFormat, today);
	const sourceEndDate = parse(end, dateRangeServerFormat, today);
	const diffInDays = differenceInDays(sourceEndDate, sourceStartDate) + 1;
	let prevStart = sub(sourceStartDate, { days: diffInDays });
	let prevEnd = sub(sourceEndDate, { days: diffInDays });
	let exactTime = false;
	let useHourlyOverride = false;
	if (dateRangeType === DATE_RANGE.monthToDate) {
		prevEnd = subMonths(sourceEndDate, 1);
		prevStart = startOfMonth(prevEnd);
	} else if (dateRangeType === DATE_RANGE.previousMonth) {
		prevStart = startOfMonth(subMonths(sourceStartDate, 1));
		prevEnd = endOfMonth(prevStart);
	}
	if (isToday(sourceEndDate)) {
		// set time component today's day is included
		const { data: lastProcessedTimestamp } = await requestLastProcessedDate({
			requestParams
		});
		if (lastProcessedTimestamp) {
			const date = new Date(lastProcessedTimestamp);
			prevEnd.setHours(date.getUTCHours() + 1); // adding 1 hour for full hour data
			prevEnd.setMinutes(date.getUTCMinutes());
			exactTime = true;
			useHourlyOverride = true;
		} else {
			return {
				isError: true
			};
		}
	}

	return {
		start: format(prevStart, dateRangeServerFormat),
		end: format(
			prevEnd,
			exactTime ? dateTimeServerFormat : dateRangeServerFormat
		),
		diffInDays,
		exactTime,
		useHourlyOverride
	};
};
