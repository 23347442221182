import React from "react";
import PropTypes from "prop-types";
import {
	useGridApiContext,
	useGridSelector,
	GridPagination,
	gridPageCountSelector
} from "@mui/x-data-grid-pro";
import MuiPagination from "@mui/material/Pagination";

function Pagination({ page, onPageChange, className }) {
	const apiRef = useGridApiContext();
	const pageCount = useGridSelector(apiRef, gridPageCountSelector);

	return (
		<MuiPagination
			color="primary"
			className={className}
			count={pageCount}
			page={page + 1}
			onChange={(event, newPage) => {
				onPageChange(event, newPage - 1);
			}}
		/>
	);
}

Pagination.propTypes = {
	page: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	className: PropTypes.string.isRequired
};

function CustomPagination(props) {
	// eslint-disable-next-line react/jsx-props-no-spreading
	return <GridPagination ActionsComponent={Pagination} {...props} />;
}

export default CustomPagination;
