import React from "react";
import CrudForm from "../../components/CrudForm/CrudForm";
import lc from "./localeContent";
import commonLocaleContent from "../commonLocaleContent";
import FieldTypes from "../../components/CrudForm/FieldTypes";
import { buildValidationSchema } from "../../components/FormFieldExpressionBuilder/FormFieldExpressionBuilder";
import { EXPRESSION_NODE_TYPE } from "../../config/constants";

const FIELDS_CONFIG = [
	{
		key: "BASIC_SETTINGS",
		fields: [
			{
				name: "name",
				label: lc.FIELD_AUDIENCE_NAME.LABEL,
				gridConfig: { md: 6 },
				isRequired: true
			},
			{
				name: "description",
				label: lc.FIELD_DESCRIPTION.LABEL,
				gridConfig: { md: 6 }
			}
		]
	},
	{
		key: "AUDIENCE_BUILDER",
		fields: [
			{
				name: "expression",
				fieldType: FieldTypes.EXPRESSION_BUILDER,
				validationSchema: buildValidationSchema({
					leafName: lc.FIELD_EXPRESSION.LEAF_NAME_LABEL,
					leafNodeKey: EXPRESSION_NODE_TYPE.SEGMENT
				}),
				fieldTypeOptions: {
					leafOptionsConfig: {
						leafOptionsEndpoint: "manage/segment-options",
						leafOptionIdKey: "segmentId",
						existingLeafField: "segments",
						leafTableColumnsConfig: [
							{
								Header: lc.AUDIENCE_LEAF_COLUMN_HEADERS.NAME,
								accessor: "name",
								isSortable: true
							},
							{
								Header: lc.AUDIENCE_LEAF_COLUMN_HEADERS.PROVIDER,
								accessor: "provider"
							},
							{
								Header: lc.AUDIENCE_LEAF_COLUMN_HEADERS.CPM,
								accessor: "cpm",
								align: "right"
							},
							{
								Header: lc.AUDIENCE_LEAF_COLUMN_HEADERS.COOKIE_REACH,
								accessor: "cookieReach",
								align: "right"
							},
							{
								Header: lc.AUDIENCE_LEAF_COLUMN_HEADERS.DEVICE_REACH,
								accessor: "deviceReach",
								align: "right"
							}
						]
					},
					leafExpressionNodeRepresentation: {
						nodeType: EXPRESSION_NODE_TYPE.SEGMENT,
						idKey: "segmentId",
						labelKey: "name"
					},
					emptyExpressionWarning: lc.FIELD_EXPRESSION.EMPTY_EXPRESSION_WARNING
				}
			}
		]
	}
];

function AudienceResource() {
	return (
		<CrudForm
			resourceEndpoint="manage/audiences"
			resourceRoute="audiences"
			resourceString={commonLocaleContent.AUDIENCE}
			fieldsConfig={FIELDS_CONFIG}
			preSubmit={formValues => ({
				...formValues,
				status: "active"
			})}
		/>
	);
}

export default AudienceResource;
