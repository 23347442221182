import React, { useState, useEffect, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { useParams, Redirect } from "react-router-dom";
import * as Yup from "yup";

import { useResourceClient, Operations } from "../../hooks/useResource";
import { useResourceAsync } from "../../hooks/useResourceAsync";
import useQueryParam from "../../hooks/useQueryParam";
import FieldTypes, { getFieldTypeOrDefault } from "./FieldTypes";
import LoadingBackdrop from "../LoadingBackdrop/LoadingBackdrop";
import localeContent from "./localeContent";
import CrudFormFormik from "./CrudFormFormik";
import { isEmpty, objectMap } from "../../utils/objectUtils";
import { updateCompanyIdQueryStringParameter } from "../../utils/endpointUtils";
import { PARENT_ID_PARAM_KEY } from "./constants";
import { SnackbarContext } from "../../context/SnackbarContext";
import { AuthContext } from "../../context/AuthContext";
import { BREADCRUMBS_ENUM } from "../../config/constants";
import useParsedQueryParams from "../../hooks/useParsedQueryParams";
import { AuthenticatedUserSettingsContext } from "../../context/AuthenticatedUserSettingsContext";

/**
 * Reduces fieldsConfig or tabsConfig to a flat array of field config objects
 * @param {Array} fieldGroupsConfig list of field group config objects
 * @param {Array} tabsConfig list of tab config objects, each with their own fieldsConfig object
 */
const getFlatFieldsConfigArray = (tabsConfig, fieldGroupsConfig = []) => {
	let fieldGroupsForReduce = fieldGroupsConfig;
	if (!isEmpty(tabsConfig)) {
		// If we have tabs, reduce each tab's field groups to a flat array
		fieldGroupsForReduce = tabsConfig.reduce((aggregator, tabConfig) => {
			let tabFieldsConfig = tabConfig.fieldsConfig || [];
			if (tabConfig.componentConfigTop) {
				tabFieldsConfig = [
					...tabFieldsConfig,
					{ fields: [tabConfig.componentConfigTop] }
				];
			}

			return [...aggregator, ...tabFieldsConfig];
		}, []);
	}
	// Get fields out of field groups
	return fieldGroupsForReduce.reduce(
		(aggregator, fieldGroupConfig) => [
			...aggregator,
			...fieldGroupConfig.fields
		],
		[]
	);
};

/**
 * Returns the default value for the field (making sure to pass false-y values)
 * @param {Object} fieldConfig config object
 */
const getFieldDefaultValue = initData => (fieldConfig, formContext) => {
	const fieldType = getFieldTypeOrDefault(fieldConfig);
	const transformInitData =
		fieldConfig.transformInitData || fieldType.transformInitData;

	if (transformInitData) {
		return transformInitData(
			initData,
			fieldConfig.name,
			fieldConfig,
			formContext
		);
	}
	if (Object.prototype.hasOwnProperty.call(initData, fieldConfig.name)) {
		return initData[fieldConfig.name];
	}
	// Account for fields with "falsy values" by checking for property itself rather than value of property
	if (Object.prototype.hasOwnProperty.call(fieldConfig, "defaultValue")) {
		return fieldConfig.defaultValue;
	}
	return "";
};

/**
 * Returns a map of field names with their initial values
 * initialValues is used in Formik configuration, which tells Formik about the existence of field "names", as well as their default values
 * @param {Array} flatFieldsConfig list of field config objects
 * @param {Object} initData data to use for default form values
 */
const getInitialFormValues = (flatFieldsConfig, initData, formContext) => {
	return flatFieldsConfig.reduce((map, fieldConfig) => {
		const fieldType = getFieldTypeOrDefault(fieldConfig);
		if (fieldConfig.isNonField || fieldType.isNonField) {
			return {
				...map
			};
		}
		return {
			...map,
			[fieldConfig.name]: getFieldDefaultValue(initData)(
				fieldConfig,
				formContext
			)
		};
	}, {});
};

const shouldValidateFieldOnEdit = (disableOnEdit, isCreateResource) =>
	disableOnEdit && !isCreateResource;

/**
 * Returns a field's default validations along with a "required" validation if isRequired:true in fieldConfig
 * @param {Object} fieldConfig config object
 */

const getFieldValidations = (fieldConfig, isCreateResource, formContext) => {
	let { baseValidation } = getFieldTypeOrDefault(fieldConfig);
	if (fieldConfig.isRequired) {
		baseValidation = baseValidation.required(
			localeContent.REQUIRED_FIELD_WARNING
		);
	}
	if (!shouldValidateFieldOnEdit(fieldConfig.disableOnEdit, isCreateResource)) {
		if (fieldConfig.validationSchema) {
			baseValidation = baseValidation.concat(fieldConfig.validationSchema);
		}

		if (fieldConfig.getValidationSchema) {
			baseValidation = baseValidation.concat(
				fieldConfig.getValidationSchema(formContext)
			);
		}
	}

	return baseValidation;
};

/**
 * Returns an object validation schema of field names -> Yup validations
 * @param {Array} flatFieldsConfig list of field config objects
 */
const getValidationSchema = (
	flatFieldsConfig,
	isCreateResource,
	formContext
) => {
	return flatFieldsConfig
		.filter(fieldConfig =>
			Object.prototype.hasOwnProperty.call(
				fieldConfig.fieldType || FieldTypes.TEXT,
				"baseValidation"
			)
		)
		.reduce(
			(map, fieldConfig) => ({
				...map,
				[fieldConfig.name]: getFieldValidations(
					fieldConfig,
					isCreateResource,
					formContext
				)
			}),
			{}
		);
};

const getBarTitle = (resourceString, isCreateResource, isSingleView) => {
	if (isSingleView) {
		return resourceString;
	}
	const prefix = isCreateResource
		? localeContent.CREATE_RESOURCE_TITLE
		: localeContent.EDIT_RESOURCE_TITLE;
	return `${prefix} ${resourceString}`;
};

const mergeDataSources = (initData, submissionData, queryParamData = {}) => {
	return {
		...queryParamData,
		...(submissionData || initData)
	};
};

const defaultMapCloneData = data => ({ ...data, name: "" });

const getInitParams = (
	prepopulateCreate,
	isCreateResource,
	shouldCloneEntity,
	id,
	isSingleView
) => {
	if (isSingleView) {
		return {};
	}
	const params = { requestParams: {} };
	// Clone
	if (shouldCloneEntity) {
		return {
			...params,
			resourceId: id
		};
	}
	// Create (if applicable)
	if (isCreateResource && prepopulateCreate) {
		return {
			...params,
			resourceId: "INIT"
		};
	}
	// Edit
	if (!isCreateResource) {
		return {
			...params,
			resourceId: id
		};
	}
	return null;
};

/**
 * Used to provide values to certain form fields based on query parameters
 * Currently used for the parent field present in any forms that require one
 * @param {String} parentIdField name of the form's parentIdField, if any
 * @param {Number} parentId the value of the parentId query parameter
 */
const buildQueryParamsData = (parentIdField, parentId) => {
	if (!parentIdField) return {};
	return {
		[parentIdField]: parentId
	};
};

const parseParentIdParam = parentIdParam => {
	const parentId = parseInt(parentIdParam, 10);
	return Number.isNaN(parentId) ? null : parentId;
};

const transformFieldDataForSubmit = (
	flatFieldsConfig,
	formValues,
	resourceId
) =>
	Object.entries(formValues).reduce((submitData, [fieldName, fieldValue]) => {
		const fieldConfig = flatFieldsConfig.find(
			config => config.name === fieldName
		);
		let { transformSubmitData } = getFieldTypeOrDefault(fieldConfig);
		if (fieldConfig.transformSubmitData) {
			transformSubmitData = fieldConfig.transformSubmitData;
		}
		return {
			...submitData,
			...(transformSubmitData
				? // TODO: Refactor the transformSubmitData interface to reduce the number of arguments
				  transformSubmitData(fieldValue, fieldName, resourceId, fieldConfig)
				: { [fieldName]: fieldValue })
		};
	}, {});

const singleViewInitOperation = {
	...Operations.ONE,
	getResourceEndpoint: baseResourcePath => baseResourcePath,
	getRequestParams: () => null
};

const singleViewSubmitOperation = {
	...Operations.UPDATE,
	getResourceEndpoint: baseResourcePath => baseResourcePath,
	getRequestParams: () => null
};

const getSubmitOperation = (isCreateResource, isSingleView) => {
	if (isSingleView) {
		return singleViewSubmitOperation;
	}
	return isCreateResource ? Operations.CREATE : Operations.UPDATE;
};

const getPostSubmitRedirect = ({
	isSingleView,
	redirectOverride,
	submissionData,
	resourceRoute,
	resourceEndpoint,
	companyId
}) => {
	if (!isSingleView) {
		let redirectUrl;
		if (redirectOverride) {
			redirectUrl = redirectOverride;
		} else if (submissionData) {
			redirectUrl = `/dashboard/${resourceRoute || resourceEndpoint}/${
				submissionData.id
			}`;
		}
		if (redirectUrl) {
			return updateCompanyIdQueryStringParameter(redirectUrl, companyId);
		}
	}
	return null;
};

function getAuditEvent(initData, submitData) {
	if (submitData?.auditEvent) {
		return submitData.auditEvent;
	}

	if (initData?.auditEvent) {
		return initData.auditEvent;
	}

	return undefined;
}

function CrudForm(props) {
	const {
		resourceString,
		fieldsConfig,
		tabsConfig,
		preSubmit,
		postSubmit,
		resourceEndpoint,
		resourceRoute,
		prepopulateCreate,
		isSingleView,
		parentIdField,
		parentStatusKey,
		entityNameKey,
		breadcrumbsConfig,
		mapCloneDataOverride,
		customStartPathEndPoint,
		cloneUrlWithId,
		viewReportConfig
	} = props;

	const { triggerNewSnackbarMessage } = useContext(SnackbarContext);

	const { id } = useParams();
	const cloneParamValue = useQueryParam("clone");
	const shouldCloneEntity = id && id !== "INIT" && Boolean(cloneParamValue);
	const isCreateResource = id === "INIT" || shouldCloneEntity;
	const authContext = useContext(AuthContext);

	const mapCloneData = mapCloneDataOverride || defaultMapCloneData;

	const flatFieldsConfig = useMemo(
		() => getFlatFieldsConfigArray(tabsConfig, fieldsConfig),
		[fieldsConfig, tabsConfig]
	);

	const authenticatedUserSettings = useContext(
		AuthenticatedUserSettingsContext
	);
	const userTimeZone = authenticatedUserSettings
		? authenticatedUserSettings.userTimeZone
		: null;

	// Pull query param data out of params per field config
	const allParams = useParsedQueryParams();
	const saturatedParamsData = useMemo(() => {
		return flatFieldsConfig.reduce((agg, fieldConfig) => {
			const { name, queryParamKey, transformQueryParams } = fieldConfig;
			if (queryParamKey && allParams[queryParamKey] !== undefined) {
				return {
					...agg,
					[name]: allParams[queryParamKey]
				};
			}
			if (transformQueryParams) {
				const transformedQueryParams = transformQueryParams(allParams);
				return { ...agg, ...transformedQueryParams };
			}

			return { ...agg };
		}, {});
	}, [flatFieldsConfig, allParams]);

	const parentIdParamValue = useQueryParam(PARENT_ID_PARAM_KEY);
	const parentId = parseParentIdParam(parentIdParamValue);
	const queryParamData = useMemo(
		() => ({
			...saturatedParamsData,
			...buildQueryParamsData(parentIdField, parentId)
		}),
		[parentIdField, parentId, saturatedParamsData]
	);

	// TODO: Do not make INIT request until we have a parentId. This may require changing tests...
	const initParams = getInitParams(
		prepopulateCreate,
		isCreateResource,
		shouldCloneEntity,
		id,
		isSingleView
	);

	const [
		initData,
		initError,
		isInitLoading,
		requestInitData
	] = useResourceClient(
		resourceEndpoint,
		isSingleView ? singleViewInitOperation : Operations.ONE,
		initParams,
		customStartPathEndPoint
	);

	// Re-request INIT data whenever the parentId changes during the create flow
	useEffect(() => {
		if (isCreateResource && parentIdField && parentId) {
			requestInitData({
				resourceId: "INIT",
				requestParams: {
					[parentIdField]: parentId
				}
			});
		}
	}, [parentIdField, parentId, isCreateResource, requestInitData]);

	const submitResourceEndpoint =
		shouldCloneEntity && cloneUrlWithId
			? `${resourceEndpoint}/${id}/clone`
			: resourceEndpoint;

	const {
		data: submissionData,
		isLoading: isSubmitting,
		execute: submitFormData
	} = useResourceAsync(
		submitResourceEndpoint,
		getSubmitOperation(isCreateResource, isSingleView),
		{ preserveDataOnExecute: true, customStartPathEndPoint }
	);

	const [redirectOverride, setRedirectOverride] = useState(null);
	const [postSubmitRedirect, setPostSubmitRedirect] = useState(null);
	const [dirtyOverride, setDirtyOverride] = useState({});

	const formContext = useMemo(
		() => ({
			parentIdFieldKey: PARENT_ID_PARAM_KEY,
			parentIdField,
			isCreateResource,
			resourceId: isCreateResource ? null : id,
			auditEvent: getAuditEvent(initData, submissionData),
			dirtyOverride,
			setDirtyOverride,
			redirectOverride,
			setRedirectOverride,
			userTimeZone
		}),
		[
			isCreateResource,
			parentIdField,
			id,
			initData,
			submissionData,
			dirtyOverride,
			setDirtyOverride,
			redirectOverride,
			setRedirectOverride,
			userTimeZone
		]
	);

	const validationSchema = useMemo(
		() =>
			Yup.object(
				getValidationSchema(flatFieldsConfig, isCreateResource, formContext)
			),
		[flatFieldsConfig, isCreateResource, formContext]
	);

	const initialFormikValues = useMemo(() => {
		const data = shouldCloneEntity ? mapCloneData(initData) : { ...initData };
		return getInitialFormValues(
			flatFieldsConfig,
			mergeDataSources(data, submissionData, queryParamData),
			formContext
		);
	}, [
		flatFieldsConfig,
		queryParamData,
		initData,
		submissionData,
		shouldCloneEntity,
		mapCloneData,
		formContext
	]);

	useEffect(() => {
		if (initData && initData[parentStatusKey]) {
			const message = initData[parentStatusKey];
			triggerNewSnackbarMessage({
				dismissOnLocationChange: true,
				message,
				severity: "warning",
				forceDismiss: true
			});
		}
	}, [initData, parentStatusKey, triggerNewSnackbarMessage]);

	const companyId = authContext?.companyId;

	const breadCrumbs = useMemo(() => {
		if (!initData) return [];
		return breadcrumbsConfig.map(breadcrumbConfig => {
			const {
				responseKeyName,
				responseKeyId,
				baseRoute,
				key
			} = breadcrumbConfig;
			const isCurrentTitle = responseKeyName === BREADCRUMBS_ENUM.NAME.key;
			const title = isCurrentTitle
				? initialFormikValues[responseKeyName]
				: initData[responseKeyName];
			const href = baseRoute
				? `${baseRoute}/${initData[responseKeyId]}`
				: undefined;
			return {
				title,
				href,
				key
			};
		});
	}, [breadcrumbsConfig, initData, initialFormikValues]);

	return (
		<>
			{postSubmitRedirect && <Redirect to={postSubmitRedirect} />}
			<LoadingBackdrop isOpen={isInitLoading || isSubmitting} />
			<Formik
				enableReinitialize
				initialValues={initialFormikValues}
				validationSchema={validationSchema}
				onSubmit={async (values, actions) => {
					const { setErrors, resetForm } = actions;
					const transformedFieldData = transformFieldDataForSubmit(
						flatFieldsConfig,
						values,
						id
					);
					const { data, error } = await submitFormData({
						data: preSubmit(transformedFieldData, isCreateResource ? null : id),
						resourceId: id
					});
					if (error) {
						const { detail, message } = error;
						if (detail) {
							setErrors(
								objectMap(detail, (value, key) => {
									const fieldConfig = flatFieldsConfig.find(
										({ name }) => name === key
									);
									// if field's fieldType has a function, use it
									const fieldTypeErrorParser =
										// Adding the condition here, because it was failing if a key from API has an error and that key is not there in the resource file.
										fieldConfig &&
										getFieldTypeOrDefault(fieldConfig).customErrorParseFunction;
									if (fieldTypeErrorParser) {
										const parsedValue = fieldTypeErrorParser(value);
										return parsedValue;
									}
									// use the default error behavior
									return value.join(" - ");
								})
							);
						} else if (message) {
							triggerNewSnackbarMessage({ message, severity: "error" });
						}
					}
					if (data) {
						triggerNewSnackbarMessage({
							message: localeContent.SUCCESS_MESSAGE(
								data[entityNameKey] || data.name,
								isCreateResource
							),
							severity: "success"
						});
						postSubmit(data);
						resetForm({ values: getInitialFormValues(flatFieldsConfig, data) });
						setPostSubmitRedirect(
							getPostSubmitRedirect({
								isSingleView,
								redirectOverride,
								submissionData: data,
								resourceRoute,
								resourceEndpoint,
								companyId
							})
						);
					}
				}}
			>
				<CrudFormFormik
					formContext={formContext}
					initData={initData}
					initError={initError}
					initParams={initParams}
					tabsConfig={tabsConfig}
					breadCrumbs={breadCrumbs}
					fieldsConfig={fieldsConfig}
					flatFieldsConfig={flatFieldsConfig}
					setRedirectOverride={setRedirectOverride}
					resourceString={resourceString}
					barTitle={getBarTitle(resourceString, isCreateResource, isSingleView)}
					resourceId={id}
					showFormContent={Boolean(
						initData || initError || submissionData || !initParams
					)}
					viewReportConfig={viewReportConfig}
				/>
			</Formik>
		</>
	);
}

CrudForm.propTypes = {
	resourceEndpoint: PropTypes.string,
	resourceRoute: PropTypes.string,
	resourceString: PropTypes.string,
	fieldsConfig: PropTypes.arrayOf(PropTypes.shape()),
	tabsConfig: PropTypes.arrayOf(PropTypes.shape()),
	preSubmit: PropTypes.func,
	postSubmit: PropTypes.func,
	breadcrumbsConfig: PropTypes.arrayOf(PropTypes.shape()),
	prepopulateCreate: PropTypes.bool,
	isSingleView: PropTypes.bool,
	parentIdField: PropTypes.string,
	parentStatusKey: PropTypes.string,
	entityNameKey: PropTypes.string,
	mapCloneDataOverride: PropTypes.func,
	customStartPathEndPoint: PropTypes.func,
	cloneUrlWithId: PropTypes.bool,
	viewReportConfig: PropTypes.shape()
};

CrudForm.defaultProps = {
	resourceEndpoint: "defaultEndpoint",
	resourceString: "Resource",
	resourceRoute: null,
	fieldsConfig: [],
	tabsConfig: [],
	preSubmit: values => values,
	postSubmit: values => values,
	breadcrumbsConfig: [],
	prepopulateCreate: false,
	isSingleView: false,
	parentIdField: null,
	parentStatusKey: null,
	entityNameKey: null,
	mapCloneDataOverride: null,
	customStartPathEndPoint: undefined,
	cloneUrlWithId: false,
	viewReportConfig: null
};

export default CrudForm;
