import React from "react";
import PropTypes from "prop-types";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import {
	DialogTitle,
	DialogContent,
	DialogActions,
	Grid,
	IconButton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Form } from "formik";

import FormTextField from "../FormTextField/FormTextField";
import FormField from "../CrudForm/FormField";
import commonLocaleContent from "../../screens/commonLocaleContent";
import { MUI_GRID_CONTAINER_SPACING } from "../../config/constants";
import lc from "./localeContent";

const dialogTitleId = "segment-file-dialog-title";

function SegmentForm({ isCreate, closeModal }) {
	return (
		<Paper>
			<Form noValidate>
				<DialogTitle id={dialogTitleId}>
					{isCreate ? lc.ADD_SEGMENT_MODAL_LABEL : lc.EDIT_SEGMENT_MODAL_LABEL}
					<IconButton
						aria-label={commonLocaleContent.CLOSE}
						onClick={closeModal}
						sx={theme => ({
							position: "absolute",
							right: theme.spacing(1),
							top: theme.spacing(1)
						})}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={MUI_GRID_CONTAINER_SPACING}>
						<Grid item xs={12}>
							<FormField>
								<FormTextField
									name="name"
									id="name"
									label={lc.FIELD.SEGMENT_NAME.LABEL}
									required
								/>
							</FormField>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeModal}>{commonLocaleContent.CANCEL}</Button>
					<Button
						color="primary"
						variant="contained"
						type="submit"
						startIcon={<SaveIcon />}
					>
						{commonLocaleContent.SAVE}
					</Button>
				</DialogActions>
			</Form>
		</Paper>
	);
}

SegmentForm.propTypes = {
	isCreate: PropTypes.bool.isRequired,
	closeModal: PropTypes.func.isRequired
};

export default SegmentForm;
