import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import qs from "qs";

import { AuthContext } from "./AuthContext";
import { DASHBOARD_API_URL } from "../config/ApiUrlConfig";

const FetchContext = createContext();
const { Provider } = FetchContext;
function FetchProvider({ children }) {
	const authContext = useContext(AuthContext);
	const authAxios = axios.create({
		baseURL: DASHBOARD_API_URL,
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			"X-Requested-With": "XMLHttpRequest"
		},
		// We're using qs to help with serialzing objects and arrays when consructing query parameters
		// https://github.com/ljharb/qs#stringifying
		paramsSerializer: params =>
			qs.stringify(params, {
				arrayFormat: "brackets", // Per the docs: "When arrays are stringified, by default they are given explicit indices:" Our server was throwing errors because of this behavior
				encode: true // This allows us to separate concerns, while properties in objects can be sent with special characters, this here will url encode the parameters names and
				// value in case that is needed
			})
	});

	authAxios.interceptors.request.use(
		config => {
			const configWithHeaders = { ...config };
			if (authContext.authState.token) {
				configWithHeaders.headers.Authorization = `Bearer ${authContext.authState.token}`;
			}
			// with X-COMPANY-OVERRIDE header only user with SEE_ALL_COMPANIES has permission can access that request
			if (authContext.companyId !== authContext.primaryCompanyId) {
				configWithHeaders.headers["X-COMPANY-OVERRIDE"] = authContext.companyId;
			}
			return configWithHeaders;
		},
		error => {
			return Promise.reject(error);
		}
	);

	authAxios.interceptors.response.use(
		response => {
			return response;
		},
		error => {
			const code = error && error.response ? error.response.status : 0;
			if (code === 401) {
				authContext.logout();
			}
			if (code === 403) {
				authContext.setAccessDenied(true);
			}
			return Promise.reject(error);
		}
	);

	return (
		<Provider
			value={{
				authAxios
			}}
		>
			{children}
		</Provider>
	);
}

FetchProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export { FetchContext, FetchProvider };
