// Component design based on https://material-ui.com/components/drawers/#responsive-drawer mobile-view temporary drawer
import React, { useContext } from "react";
import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import { useHistory } from "react-router-dom";

import RouterLink from "../../Routing/LinkWithQuery";
import { AuthContext } from "../../../context/AuthContext";
import lc from "../localeContent";
import {
	COMPANY_ID_QUERY_PARAM,
	PROTECTED_CONTENT_PATH
} from "../../../config/constants";

const COMPANY_FIELD_NAME = "company";

const drawerWidth = 300;

function NavModal(props) {
	const {
		getBrandingResource,
		isOpen,
		handleModalToggle,
		clickedOptionConfig,
		activeMenuItemKey,
		activeMenuSubItemKey,
		companyList
	} = props;
	const authContext = useContext(AuthContext);
	const history = useHistory();

	const handleChange = (event, newValue) => {
		handleModalToggle();
		history.push(
			`${PROTECTED_CONTENT_PATH}?${COMPANY_ID_QUERY_PARAM}=${newValue.id}`
		);
	};

	const companyListOptions =
		companyList && companyList.sort((a, b) => -b.group.localeCompare(a.group));

	const activeCompanyObj = () => {
		return (
			companyListOptions &&
			companyListOptions.find(c => c.id === authContext.companyId)
		);
	};

	return (
		<Drawer
			variant="temporary"
			anchor="left"
			open={isOpen}
			onClose={handleModalToggle}
			sx={{
				"& .MuiDrawer-paper": {
					width: drawerWidth
				}
			}}
			ModalProps={{
				keepMounted: true
			}}
		>
			<Box>
				<Typography variant="h5" sx={{ ml: 2, my: 1 }}>
					{activeCompanyObj() ? activeCompanyObj().name : ""}
				</Typography>
				<Avatar
					variant="rounded"
					src={getBrandingResource("branding/logo")}
					alt={lc.COMPANY_LOGO_ALT_TEXT}
					imgProps={{
						style: {
							objectFit: "scale-down",
							padding: "8px"
						}
					}}
					sx={{
						backgroundColor: "secondary.800",
						width: "270px",
						height: "90px",
						ml: 2
					}}
				/>
			</Box>
			{clickedOptionConfig?.navItemType === "companySwitch" && (
				<Box flexGrow={1}>
					<Autocomplete
						disableClearable
						id={COMPANY_FIELD_NAME}
						name={COMPANY_FIELD_NAME}
						options={companyListOptions || []}
						groupBy={option => option.group}
						getOptionLabel={option => option.name}
						getOptionKey={option => option.id}
						isOptionEqualToValue={option => {
							return option.id === authContext.companyId;
						}}
						onChange={handleChange}
						renderInput={params => (
							<TextField
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...params}
								label={lc.ACTIVE_COMPANY_LABEL}
							/>
						)}
						sx={{ mt: 2, mx: 2 }}
						value={activeCompanyObj()}
					/>
				</Box>
			)}
			{clickedOptionConfig?.navItemType === "management" && (
				<Box flexGrow={1}>
					<List>
						<ListItem>
							<ListItemIcon>
								<clickedOptionConfig.iconComponent
									color={
										clickedOptionConfig.key === activeMenuItemKey
											? "primary"
											: "inherit"
									}
								/>
							</ListItemIcon>
							<ListItemText>{clickedOptionConfig.tooltip}</ListItemText>
						</ListItem>
						<Divider />
						{clickedOptionConfig.subItems.map(item => (
							<ListItemButton
								key={item.key}
								dense
								component={RouterLink}
								onClick={handleModalToggle}
								to={item.route || ""}
								selected={activeMenuSubItemKey === item.key}
								sx={{ pl: 4 }}
							>
								<ListItemText inset>{item.title}</ListItemText>
							</ListItemButton>
						))}
					</List>
				</Box>
			)}
			<List sx={{ pb: 0 }}>
				<ListItemButton dense sx={{ pb: 1 }} onClick={handleModalToggle}>
					<Box flexGrow={1} />
					<ListItemIcon sx={theme => ({ minWidth: theme.spacing(4) })}>
						<ChevronLeftIcon />
					</ListItemIcon>
					<ListItemText sx={{ flexGrow: 0 }}>
						{lc.COLLAPSE_BUTTON_LABEL}
					</ListItemText>
				</ListItemButton>
			</List>
		</Drawer>
	);
}

NavModal.propTypes = {
	getBrandingResource: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	handleModalToggle: PropTypes.func.isRequired,
	clickedOptionConfig: PropTypes.shape(),
	activeMenuItemKey: PropTypes.string,
	activeMenuSubItemKey: PropTypes.string,
	companyList: PropTypes.arrayOf(PropTypes.shape())
};

NavModal.defaultProps = {
	clickedOptionConfig: {},
	activeMenuItemKey: "",
	activeMenuSubItemKey: "",
	companyList: []
};

export default NavModal;
