import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Paper, Tooltip, Link } from "@mui/material";
import RouterLink from "../Routing/LinkWithQuery";
import {
	formatAsCurrency,
	formatAsNumberWithSeparator,
	formatAsDate,
	formatAsEnabled,
	formatAsCapitalized
} from "../../utils/formatters";
import BaseDataGrid from "../BaseDataGrid/BaseDataGrid";

const updateColumn = (column, primaryColumnKey, showGuidColumn) => {
	const { key, name, resourceRoute, formatter, cellDataType, width } = column;
	if ((key === "name" || key === primaryColumnKey) && resourceRoute) {
		return {
			hideable: false,
			headerAlign: "left",
			align: "left",
			field: key,
			headerName: name,
			default: true,
			sortable: true,
			minWidth: 200,
			width: width || 300,
			flex: 0.75,
			renderCell: params => (
				<Link
					to={`/dashboard/${resourceRoute}/${params.id}`}
					component={RouterLink}
					color="inherit"
				>
					{params.value && (
						<Tooltip title={params.value}>
							<span className="table-cell-truncate">{params.value}</span>
						</Tooltip>
					)}
				</Link>
			)
		};
	}
	if (key === "actions") {
		return {
			hideable: false,
			headerAlign: "center",
			align: "center",
			field: key,
			width: 30,
			headerName: "",
			default: true,
			sortable: false,
			minWidth: 30,
			renderCell: params => formatter(null, params)
		};
	}
	if (column.key === "guid") {
		return {
			hideable: false,
			headerAlign: "center",
			align: "center",
			field: key,
			width: width || 250,
			headerName: name,
			default: true,
			sortable: false,
			minWidth: 50,
			hide: !showGuidColumn
		};
	}
	switch (cellDataType) {
		case "date":
			return {
				headerAlign: "left",
				align: "left",
				field: key,
				width: width || 120,
				headerName: name,
				default: true,
				sortable: true,
				minWidth: 50,
				valueFormatter: ({ value }) => formatAsDate(value)
			};
		case "upperCase":
			return {
				headerAlign: "left",
				align: "left",
				field: key,
				width: width || 100,
				headerName: name,
				default: true,
				sortable: true,
				minWidth: 50,
				valueFormatter: ({ value }) => formatAsCapitalized(value)
			};
		case "number":
			return {
				headerAlign: "right",
				align: "right",
				field: key,
				width: width || 120,
				headerName: name,
				default: true,
				sortable: true,
				minWidth: 100,
				valueFormatter: ({ value }) => formatAsNumberWithSeparator(value)
			};
		case "enabled":
			return {
				headerAlign: "left",
				align: "left",
				field: key,
				width: width || 120,
				headerName: name,
				default: true,
				sortable: true,
				minWidth: 50,
				valueFormatter: ({ value }) => formatAsEnabled(value)
			};
		case "currency":
			return {
				headerAlign: "right",
				align: "right",
				field: key,
				width: width || 120,
				headerName: name,
				default: true,
				sortable: true,
				minWidth: 50,
				valueFormatter: ({ value }) => formatAsCurrency(value)
			};
		default:
			return {
				headerAlign: "left",
				align: "left",
				field: key,
				headerName: name,
				default: true,
				sortable: true,
				minWidth: 150,
				width: width || 200,
				flex: 0.5,
				renderCell: params =>
					params.value && (
						<Tooltip title={params.value}>
							<span className="table-cell-truncate">{params.value}</span>
						</Tooltip>
					)
			};
	}
};

function CrudDataGrid(props) {
	const {
		disclaimer,
		columns,
		filteredData,
		paginationConfig,
		heading,
		gridHeight,
		tableTestId,
		primaryColumnKey,
		footerActionButton,
		showGuidColumn,
		gridAriaLabel
	} = props;
	const newColumnConfig = useMemo(() => {
		return columns.map(column =>
			updateColumn(column, primaryColumnKey, showGuidColumn)
		);
	}, [columns, primaryColumnKey, showGuidColumn]);

	const defaultSortConfig = useMemo(() => {
		const isUpdatedAt = columns.find(column => column.key === "updatedAt");

		return {
			field: isUpdatedAt ? "updatedAt" : "createdAt",
			sort: "desc"
		};
	}, [columns]);

	return (
		<Paper>
			<BaseDataGrid
				rows={filteredData}
				columns={newColumnConfig}
				tableTestId={tableTestId}
				gridHeight={gridHeight}
				defaultSortConfig={defaultSortConfig}
				paginationConfig={paginationConfig}
				heading={heading}
				disclaimer={disclaimer}
				gridAriaLabel={gridAriaLabel}
				footerActionButton={footerActionButton}
			/>
		</Paper>
	);
}

CrudDataGrid.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	filteredData: PropTypes.arrayOf(PropTypes.shape()),
	disclaimer: PropTypes.string,
	paginationConfig: PropTypes.shape({
		defaultRowsPerPage: PropTypes.number
	}),
	heading: PropTypes.string,
	gridHeight: PropTypes.number,
	tableTestId: PropTypes.string,
	primaryColumnKey: PropTypes.string,
	gridAriaLabel: PropTypes.string.isRequired,
	footerActionButton: PropTypes.shape(),
	showGuidColumn: PropTypes.bool
};
CrudDataGrid.defaultProps = {
	disclaimer: null,
	filteredData: [],
	paginationConfig: {},
	heading: null,
	gridHeight: null,
	tableTestId: null,
	primaryColumnKey: null,
	footerActionButton: null,
	showGuidColumn: false
};

export default CrudDataGrid;
