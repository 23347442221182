import React, { useContext, useMemo } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
	Box,
	Paper,
	Grid,
	InputAdornment,
	IconButton,
	Container
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useResourceClient, Operations } from "../../hooks/useResource";
import { useResourceAsync } from "../../hooks/useResourceAsync";
import FormFieldSelect from "../../components/FormFieldSelect/FormFieldSelect";
import FormTextField from "../../components/FormTextField/FormTextField";
import PageTitleBar from "../../components/PageTitleBar/PageTitleBar";
import TableRowActionDelete from "../../components/CrudDataGrid/TableRowActionDelete";
import LoadingBackdrop from "../../components/LoadingBackdrop/LoadingBackdrop";
import { SnackbarContext } from "../../context/SnackbarContext";
import { objectMap } from "../../utils/objectUtils";
import { MUI_GRID_CONTAINER_SPACING } from "../../config/constants";
import lc from "./localeContent";
import CrudDataGrid from "../../components/CrudDataGrid/CrudDataGrid";

const RULE_TYPE_OPTIONS = [
	{
		value: "HEADER_ACCEPT_ENCODING",
		label: lc.RULE_TYPE_OPTION_LABEL.HEADER_ACCEPT_ENCODING
	},
	{
		value: "HEADER_USER_AGENT",
		label: lc.RULE_TYPE_OPTION_LABEL.HEADER_USER_AGENT
	},
	{ value: "HEADER_COOKIE", label: lc.RULE_TYPE_OPTION_LABEL.HEADER_COOKIE },
	{ value: "HEADER_REFERER", label: lc.RULE_TYPE_OPTION_LABEL.HEADER_REFERER },
	{ value: "HEADER_HOST", label: lc.RULE_TYPE_OPTION_LABEL.HEADER_HOST },
	{ value: "REQUEST_URL", label: lc.RULE_TYPE_OPTION_LABEL.REQUEST_URL },
	{ value: "IP_ADDRESS", label: lc.RULE_TYPE_OPTION_LABEL.IP_ADDRESS },
	{ value: "PLACEMENT_GUID", label: lc.RULE_TYPE_OPTION_LABEL.PLACEMENT_GUID }
];

const KEY_RULE_TYPE = "ruleType";
const KEY_FILTER_VALUE = "filterValue";

const UPDATED_AT_KEY = "updatedAt";
const COLUMNS = [
	{ key: "typeName", name: lc.RULE_TYPE_LABEL },
	{ key: "value", name: lc.FILTER_VALUE_LABEL },
	{
		key: "createdAt",
		name: lc.COLUMN_HEADERS.CREATED_AT,
		cellDataType: "date"
	},
	{
		key: UPDATED_AT_KEY,
		name: lc.COLUMN_HEADERS.UPDATED_AT,
		cellDataType: "date"
	}
];

const initialValues = {
	[KEY_RULE_TYPE]: RULE_TYPE_OPTIONS[0].value,
	[KEY_FILTER_VALUE]: ""
};

const validationSchema = Yup.object({
	[KEY_FILTER_VALUE]: Yup.string().required(lc.FILTER_VALUE_REQUIRED_MESSAGE)
});

function TrafficFiltersResource() {
	const { triggerNewSnackbarMessage } = useContext(SnackbarContext);

	const [filtersData, , isFetching, fetchFilters] = useResourceClient(
		"manage/traffic-filters",
		Operations.LIST,
		{}
	);

	const { execute: submitFormData } = useResourceAsync(
		"manage/traffic-filters",
		Operations.CREATE
	);

	const columnsConfig = useMemo(
		() => [
			{
				key: "actions",
				padding: "checkbox",
				preventSort: true,
				preventVisibilityToggle: true,
				formatter: (value, rowData) => {
					return (
						<TableRowActionDelete
							rowData={rowData?.row}
							resourceName={lc.TRAFFIC_FILTER_NAME}
							deleteEndpoint="manage/traffic-filters"
							deleteSuccessMessage={lc.FILTER_DELETED_SUCCESS_MESSAGE}
							updateTableData={() => fetchFilters({})}
						/>
					);
				}
			},
			...COLUMNS
		],
		[fetchFilters]
	);

	return (
		<>
			<LoadingBackdrop isOpen={isFetching} testId="fetching-filters" />
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={async (values, actions) => {
					const { setErrors, resetForm } = actions;
					const { data, error } = await submitFormData({
						data: {
							type: values.ruleType,
							value: values.filterValue
						}
					});
					if (error) {
						const { detail, message } = error;
						if (detail) {
							setErrors(objectMap(detail, value => value.join(" - ")));
						} else if (message) {
							triggerNewSnackbarMessage({ message, severity: "error" });
						}
					}
					if (data) {
						fetchFilters({});
						triggerNewSnackbarMessage({
							message: lc.FILTER_ADDED_SUCCESS_MESSAGE,
							severity: "success"
						});
						resetForm({ values: initialValues });
					}
				}}
			>
				<Form noValidate>
					<PageTitleBar barTitle={lc.TRAFFIC_FILTERS_TITLE} />
					<Container maxWidth="lg">
						<Box component={Paper} my={2} p={2}>
							<Grid
								container
								spacing={MUI_GRID_CONTAINER_SPACING}
								justifyContent="flex-start"
								alignItems="flex-start"
							>
								<Grid item xs={6}>
									<FormFieldSelect
										id={KEY_RULE_TYPE}
										name={KEY_RULE_TYPE}
										label={lc.RULE_TYPE_LABEL}
										optionsConfig={{ options: RULE_TYPE_OPTIONS }}
									/>
								</Grid>
								<Grid item xs={6}>
									<FormTextField
										id={KEY_FILTER_VALUE}
										name={KEY_FILTER_VALUE}
										label={lc.FILTER_VALUE_LABEL}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label={lc.ADD_TRAFFIC_FILTER_BUTTON_LABEL}
														type="submit"
													>
														<AddIcon />
													</IconButton>
												</InputAdornment>
											)
										}}
										required
									/>
								</Grid>
							</Grid>
						</Box>
						<CrudDataGrid
							columns={columnsConfig}
							filteredData={filtersData || []}
							paginationConfig={{}}
							gridAriaLabel={lc.TRAFFIC_FILTERS_TABLE_DESCRIPTION}
						/>
					</Container>
				</Form>
			</Formik>
		</>
	);
}

TrafficFiltersResource.propTypes = {};

TrafficFiltersResource.defaultProps = {};

export default TrafficFiltersResource;
