import axios from "axios";

const PART_SIZE = 5 * 1024 * 1024; // 5 MB

const uploadParts = async (file, presignedUrls) => {
	const MAX_CONCURRENT_UPLOADS = 3;
	// s3 returns a partETag for each uploaded part that must be cached
	// and sent later in the completeMultipartUpload request
	const partETags = [];

	const uploadChunk = async startIndex => {
		const uploadPromises = [];
		// Batch process up to n parts where n = MAX_CONCURRENT_UPLOADS
		for (
			let i = startIndex;
			i < file.size && i < startIndex + MAX_CONCURRENT_UPLOADS * PART_SIZE;
			i += PART_SIZE
		) {
			const partNumber = Math.floor(i / PART_SIZE) + 1;
			const part = file.slice(i, i + PART_SIZE);
			const presignedUrl = presignedUrls[partNumber - 1];

			const uploadPromise = axios
				// Send the part to s3 using the presigned url
				.put(presignedUrl, part, {
					headers: {
						"Content-Type": file.type
					}
				}) // Save the returned eTag to send later in the completeMultipartUpload request
				.then(uploadPartResponse => ({
					eTag: uploadPartResponse.headers.etag,
					partNumber
				}));

			uploadPromises.push(uploadPromise);
		}

		const result = await Promise.all(uploadPromises);
		partETags.push(...result);

		// If we're not done processing the entire file, process another batch of parts
		if (startIndex + MAX_CONCURRENT_UPLOADS * PART_SIZE < file.size) {
			await uploadChunk(startIndex + MAX_CONCURRENT_UPLOADS * PART_SIZE);
		}
	};

	// Start recursively processing the file
	await uploadChunk(0);

	return partETags;
};

const uploadFile = async (file, filePath, entityPath, authAxios) => {
	try {
		// Step1: Get presigned urls for each part
		const response = await authAxios.get(
			`manage/${entityPath}/generate-pre-signed-urls`,
			{
				params: {
					filePath,
					partCount: Math.ceil(file.size / PART_SIZE)
				}
			}
		);
		const { uploadId, presignedUrls } = response.data;

		// Step2: Use presigned urls to upload each part with concurrency control
		const partETags = await uploadParts(file, presignedUrls);

		// Step3: Mark upload as complete
		await authAxios.post(`manage/${entityPath}/complete-multi-part-upload`, {
			filePath,
			uploadId,
			partETags
		});
	} catch (err) {
		throw new Error(`Failed to upload file ${file.name}`);
	}
};

export default uploadFile;
